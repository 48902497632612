// 評価環境用

// server settings at /settings.js
declare var settings : any;

export const environment = {
  test: false,
  production: false,
  base_url: "",
  areacode: !!location.pathname.split("/")[1] ? location.pathname.split("/")[1]: "",
  releaseDate: "2025-01-17 04:37:08+00:00",
  recommendedEnvironment: {
    android: {
      os: "9.0",
      chrome: "88"
    },
    iPhone: {
      os: "14.3"
    }
  },
  sns: {
    line: {
      id: "@534miiap",
    }
  },
  env: "stage",
  googleTagManagerId: "GTM-P2B2Q24W",
  googleApiKey: "AIzaSyCbwBDdRm7KjgahAom6uVHi9ihIWSUT6ag",
  googleMapId: "b488d8f7b3c952df",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
