//=============================================================================================
// インポート
//=============================================================================================
import { Component,  ElementRef,  OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { Subscription } from 'rxjs';
import * as dayjs from 'dayjs';

// service
import { ExpWebApiService, ReservationOption, UserSelectOption } from '../../../../http-services/exp-web-api.service';
import { HttpErrorResponseParserService } from '../../../../lib-services/http-error-response-parser.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';
import { ApplicationMessageService } from 'src/app/lib-services/application-message.service';

// module
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';

// component
import { ExpChangeComponent } from '../exp-change/exp-change.component';
import { ExpCancelComponent } from '../exp-cancel/exp-cancel.component';

// parts
import { ListParts } from '../../../../components/parts/ons-list/ons-list.component';

// interface
import { ExpFetchReservation, ExpRecalc, ExpReservation, ExpService, ExpTicket, SgServiceOption } from '../../../../interfaces/response';
import { ExpChangeType1Component } from '../exp-change-type1/exp-change-type1.component';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 観光サービス詳細画面。
 *
 * @export
 * @class HistoryExpDetailComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[history-exp-detail]',
  templateUrl: './history-exp-detail.component.html',
  styleUrls: ['./history-exp-detail.component.scss']
})
export class HistoryExpDetailComponent implements OnInit, AfterViewInit, OnDestroy {

  readonly ASSETS = {
    INFORMATION: CommonFunctionModule.getAssetsUrl('/image/button/info.png')
  } as const;

  /**
   * 通信用Subscription
   *
   * @type {Subscription}
   * @memberof HistoryExpDetailComponent
   */
  busy: Subscription;

  /**
   * ons-back-button
   *
   * @private
   * @type {ElementRef}
   * @memberof HistoryExpDetailComponent
   */
  @ViewChild('onsBackButton') private onsBackButton: ElementRef;

  /**
   * onslist parts
   *
   * @type {{
   *     header: ListParts;
   *     body: ListParts[];
   *   }}
   * @memberof HistoryExpDetailComponent
   */
  template: {

    /**
     * ヘッダー
     *
     * @type {ListParts}
     */
    header: ListParts;

    /**
     * 本文1
     *
     * @type {ListParts[]}
     */
    body1: ListParts[];

    /**
     * 本文2
     *
     * @type {ListParts[]}
     */
    body2: ListParts[];

    /**
     * オプション
     *
     * @type {ExpService["option_groups"]}
     */
    optionGroup: ExpService["option_groups"]
  };

  /**
   * 選択中オプション
   *
   * @type {{[option_id: string]: UserSelectOption}}
   * @memberof HistoryExpDetailComponent
   */
  selectedOption: {[option_id: string]: UserSelectOption};

  /**
   * 観光履歴・予定
   *
   * @type {ExpReservation}
   * @memberof HistoryExpDetailComponent
   */
  reserv: ExpReservation;

  /**
   *
   *
   * @type {ReservationOption}
   * @memberof HistoryExpDetailComponent
   */
  reservationOption: ReservationOption;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of HistoryExpDetailComponent.
   * @param {ExpWebApiService} expServ
   * @param {OnsNavigator} navigator
   * @param {Params} params
   * @param {MatDialog} dialog
   * @memberof HistoryExpDetailComponent
   */
  constructor(
    private expServ: ExpWebApiService, 
    private navigator: OnsNavigator, 
    private params: Params, 
    public dialog: MatDialog,
    private commonFunc: CommonFunctionModule,
    private errResServ: HttpErrorResponseParserService,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private appMsgServ: ApplicationMessageService,
  ) { }

  /**
   * 初期処理。
   *
   * @memberof HistoryExpDetailComponent
   */
  ngOnInit(): void {

    this.reserv = this.commonFunc.deepcopy(this.params.data.reserv);

    this.reservationOption = new ReservationOption(this.reserv, this.municipalityWebApiServ.setting.exp);

    this.createTemplate();  
  }

  /**
   * ビュー初期化後処理。
   *
   * @memberof HistoryExpDetailComponent
   */
  ngAfterViewInit(): void {

    // バックボタン
    this.onsBackButton.nativeElement.onClick = () => {
      // 履歴再描画メソッド呼び出し
      if (undefined !== this.params.data.reload) this.navigator.element.popPage({ callback: () => { this.params.data.reload(); } });
      // メールからの遷移のため画面を閉じるのみ
      else this.navigator.element.popPage();
    };
  }

  /**
   * 破棄処理。
   *
   * @memberof HistoryExpDetailComponent
   */
  ngOnDestroy(): void {
    
    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 注文のステータスを表示する。
   *
   * @return {*}  {string}
   * @memberof HistoryExpDetailComponent
   */
  getViewStatus(): string {

    switch (this.reserv.status) {
      
      case 'INIT':
      case 'USING':
        return "利用予定";
      case 'DONE':
        return "利用履歴";
      case 'CANCELED':
        return "キャンセル履歴";
      default:
        return "ステータスエラー";
    }
  }

  /**
   * 利用結果がある場合、表示する。
   *
   * @return {*}  {string}
   * @memberof HistoryExpDetailComponent
   */
   getViewResult(): string {

    switch (this.reserv.status) {
      
      case 'DONE':
        return "この予約は" + dayjs(this.reserv.end.prov_time).format('YYYY/M/D') + "に利用完了しました。";
      case 'CANCELED':
        if (this.reserv.cancel_by === "user"){
          return "この予約は" + dayjs(this.reserv.canceled_date).format('YYYY/M/D') + "にキャンセルされました。";
        }else if(this.reserv.cancel_by === "system"){
          return "この予約はキャンセルとなりました。";
        } 
        else return "この予約は事業者側の都合により、開催中止となりました。";
      default: ;
    }

    return "";
  }

  /**
   * 注文キャンセルが可能かどうか。
   *    集荷前チェックのみ。
   *
   * @return {*}  {boolean}
   * @memberof HistoryExpDetailComponent
   */
  isServiceCancel(): boolean {

    // 利用前かつ、キャンセル期限前のみキャンセル可能
    return this.expServ.isServiceCancel(this.reserv, true);
  }

  /**
   * オプション詳細ダイアログ
   *
   * @param {ExpService["option_groups"][number]["options"][number]} option
   * @memberof HistoryExpDetailComponent
   */
  onClickOptionInfo(option: ExpService["option_groups"][number]["options"][number]): void {
    this.expServ.openOptionDialog(option);
  }

  /**
   * 予約・キャンセルタップ時の処理。
   *
   * @memberof HistoryExpDetailComponent
   */
  onTransLink(): void {

    // dialog open
    const dialogRef = this.dialog.open(HistoryExpDialog, { width: "400px" });
    
    // close後の処理
    dialogRef.afterClosed().subscribe(result => {
      switch (result) {
        case "reservChange":
          if (this.reserv.service.schedule_type === 1) this.navigator.element.pushPage(ExpChangeType1Component, { data: { reserv: this.reserv, optionClass: this.reservationOption, transition: this.params.data.transition }});
          else if (this.reserv.service.schedule_type === 3) this.navigator.element.pushPage(ExpChangeComponent, { data: { reserv: this.reserv, optionClass: this.reservationOption, transition: this.params.data.transition }});
          break;
        case "reservCancel":
          this.busy = this.expServ.deleteFetchReservation(this.reserv.sg_reservation_id).subscribe({
            next: res => {
              const fetch: ExpFetchReservation = res.body;
              this.navigator.element.pushPage(ExpCancelComponent, { data: { reserv: this.reserv, fetch: fetch, transition: this.params.data.transition }});
            },
            error: this.errResServ.doParse((_err, errContent) => {
              if (errContent.smartGotoErrCode === this.appMsgServ.SERV_CONST_CODE.SG_OPTION_PRICE.CALC_FAILED) {
                const errOption: {sg_option_id: number, param: "number"|"days"|"mins", table_limit: number} = errContent.smartGotoErrOpt;
                let optionName: string = void 0;
                this.reserv.service.option_groups.some(group => {
                  const result = group.options.find(option => option.sg_option_id === errOption.sg_option_id);
                  if (result === void 0) return false;
                  optionName = result.name;
                  return true;
                });
                this.errResServ.viewErrDialog(errContent, optionName);
              }
              else this.errResServ.viewErrDialog(errContent);
            })
          })
          break;
      }
    });
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 画面描画データを作成する。
   *
   * @private
   * @memberof HistoryExpDetailComponent
   */
  private createTemplate(): void {
    
    // 料金計算
    // NOTE: recalc_funcは使わない
    const exp_result: ExpRecalc = {
      amount: 0,
      recalc_date: new Date(),
      tickets: this.reserv.usages.map(usage => {
        const tickets = usage.person_usages.map(pu => pu.ticket);
        tickets.forEach((ticket, i) => {
          ticket.ticket_type = 1;
          ticket.service_name = this.reserv.service.title;
          ticket.ticket_name = ticket.title;
          ticket.unit = this.reserv.service.user_params.numbers.find(number => number.type == usage.person_usages[i].price_property).unit;
        });
        const extension_tickets = usage.person_usages.map(pu => pu.extension_ticket).filter(ticket => !!ticket);  // 存在する延長チケットをフィルター
        extension_tickets.forEach((ticket, i) => {
          ticket.ticket_type = 2;
          ticket.service_name = this.reserv.service.title;
          ticket.ticket_name = ticket.title;
          ticket.unit = this.reserv.service.user_params.numbers.find(number => number.type == usage.person_usages[i].price_property).unit;
        });
        return (tickets.concat(extension_tickets) as ExpRecalc["tickets"]).sort((ti1, ti2) => ti1.sg_ticket_id-ti2.sg_ticket_id);;
      }).reduce((pre, cur) => pre.concat(cur),[])
    };
    exp_result.amount = exp_result.tickets.reduce((pre, cur) => pre+cur.amount, 0);
    
    // お支払い料金欄のデータ
    let paymentRow: ListParts['payment_amount']['row'] = [];

    this.reserv.tickets.forEach(ticket => {
      paymentRow.push({
        item: ticket.subject,
        type: ticket.title,
        count: ticket.number,
        amount: ticket.amount,
        unit: ticket.unit
      });
    });

    this.template = {
      header: {
        mode: 'header',
        header_list: {
          item: [
            this.reserv.service.title,
            "提供：" + this.reserv.service.shop.name
          ],
          icon: this.reserv.service.images[0] ?? ""
        }
      }, 
      body1: [{
        displayOrder: 1, 
        id: "amount", 
        mode: 'paymentAmount',
        header: 'お支払い料金',
        payment_amount: {
          row: paymentRow,
          payer_name: this.reserv.payer.name
        }
      }, {
        displayOrder: 4, 
        header: '利用開始時刻',
        common: { item: dayjs(this.reserv.start.schd_time).format("M月D日 H:mm") }
      }, {
        displayOrder: 6, 
        header: '利用終了時刻',
        common: { item: dayjs(this.reserv.end.schd_time).format("M月D日 H:mm") }
      }],
      body2: [{
        id: "cancelRule", 
        header: 'キャンセル料ルール',
        common: { item: this.expServ.getDispCancelRules(this.reserv.service.prices[0].cancel_rules) }
      }, {
        header: '店舗連絡先',
        common: { item: this.reserv.service.shop.tel }
      }],
      optionGroup: this.commonFunc.deepcopy(this.reservationOption.optionGroups)
    }

    // 予約数：キャンセル、中止以外
    if (this.reserv.status === "INIT" || this.reserv.status === "USING" || this.reserv.status === "DONE") {
      let view: string = "";

      view = this.reserv.numbers.map(n => n.value).reduce((a, b) => a + b) + this.reserv.numbers[0].unit;

      // 利用者の種別が複数あるなら
      if (this.reserv.numbers.length >= 2) {
        view += "（";

        // 内訳を作成（0人は表示しない）
        this.reserv.numbers.forEach(n => {
          if (n.value !== 0) view += n.name + "：" + n.value + n.unit + "、"
        });
        
        // 末尾のカンマを削除
        view = view.slice(0 , -1);
        view += "）";
      }

      this.template.body1.push({
        displayOrder: 2, 
        header: '予約数',
        common: { item: view }
      });
    }

    // サービス種別によって表示内容を切り替え
    if (this.reserv.service.service_type === "MOBILITY") {
      this.template.body1.push({
        displayOrder: 3, 
        header: '貸出場所',
        common: {
          item: this.reserv.start.place.name,
          link: this.commonFunc.getGoogleMapUrl(this.reserv.start.place.location)
        }
      }, {
        displayOrder: 5, 
        header: '返却場所',
        common: {
          item: this.reserv.end.place.name,
          link: this.commonFunc.getGoogleMapUrl(this.reserv.end.place.location)
        }
      });
     }
     else if (this.reserv.service.service_type === "ACTIVITY") {
      this.template.body1.push({
        displayOrder: 3, 
        header: '開催場所',
        common: {
          item: this.reserv.start.place.name,
          link: this.commonFunc.getGoogleMapUrl(this.reserv.start.place.location)
        }
      });
    }
    else ;
    
    // 表示順にソート
    this.template.body1.sort((a, b) => a.displayOrder - b.displayOrder);

    // オプションテンプレート作成
    this.selectedOption = this.reservationOption.selectedOption;

    if (this.template.optionGroup.length !== 0) {

      this.template.optionGroup = this.template.optionGroup.filter(group => {
        group.options = group.options.filter(option => {
          if (this.selectedOption[option.sg_option_id] === void 0) return false;
          switch(option.user_option) {
            case 'yesno':
              if (this.selectedOption[option.sg_option_id].yesno_param.value === false) return false; 
              break;
            case 'select':
              if (this.selectedOption[option.sg_option_id].select_param.value === void 0) return false
              break;
            case 'number':
              if (this.selectedOption[option.sg_option_id].number_param.selected === 0) return false;
              break;
          }

          return true;
        });

        if (group.options.length === 0) return false;
        else return true;
      });
    }
  }
}

/**
 * 遷移ダイアログ。
 *
 * @export
 * @class HistoryExpDialog
 */
@Component({
  selector: 'history-exp-dialog',
  templateUrl: 'history-exp-dialog.html',
})
export class HistoryExpDialog {

  /**
   * Creates an instance of HistoryExpDialog.
   * @param {MatDialogRef<HistoryExpDialog>} dialogRef
   * @memberof HistoryExpDialog
   */
  constructor(public dialogRef: MatDialogRef<HistoryExpDialog>) {}

  /**
   * 予約変更ボタン押下時のイベントハンドラ
   *
   * @memberof HistoryExpDialog
   */
  onReservChange(): void {
    this.dialogRef.close("reservChange");
  }

  /**
   * 予約キャンセルボタン押下時のイベントハンドラ
   *
   * @memberof HistoryExpDialog
   */
  onReservCancel(): void {
    this.dialogRef.close("reservCancel");
  }
}