//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Inject, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Params, OnsNavigator } from 'ngx-onsenui';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { FullCalendarComponent, CalendarOptions, DayCellMountArg, EventClickArg, EventInput } from '@fullcalendar/angular';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import * as ons from 'onsenui';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { ExpWebApiService, UserSelectOption } from '../../../http-services/exp-web-api.service';
import { CommonFunctionModule } from "../../../lib-modules/common-function.module";
import { PagerService, PageKey } from 'src/app/lib-services/pager.service';
import { GoogleTagManagerService } from '../../../lib-services/google-tag-manager.service';
import { DatePickerService } from 'src/app/lib-services/date-picker.service';

// component
import { ListParts } from "../../parts/ons-list/ons-list.component";
import { ExpConfirmComponent } from '../exp-confirm/exp-confirm.component';
import { SigninComponent, SigninService } from '../../signin/signin.component';
import { TabbarComponent } from '../../tabbar/tabbar.component';

// interface
import { parameter } from '../../../interfaces/parameter';
import { ExpOptionPlace, ExpOptionNumber, ExpService, ExpOptionTime, ExpTimeRange, ExpPlace, ExpAvailable, ExpResourceAvailable, ShopCalendar, SgServiceOption, ExpFetchReservation, Settings } from '../../../interfaces/response';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { request } from 'src/app/interfaces/request';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 観光サービス詳細画面(開始・終了時刻自由型)
 *
 * @export
 * @class ExpDetailComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'ons-page[exp-detail-type1]',
  templateUrl: './exp-detail-type1.component.html',
  styleUrls: ['./exp-detail-type1.component.scss']
})
export class ExpDetailType1Component implements OnInit, OnDestroy {

  readonly ASSETS = {
    INFORMATION: CommonFunctionModule.getAssetsUrl('/image/button/info.png')
  } as const;

  /**
   * http-busy
   *
   * @type {Subscription}
   * @memberof ExpDetailType1Component
   */
   busy: Subscription;

  /**
   * HTML用
   *
   * @memberof ExpDetailType1Component
   */
  moment = moment;

  /**
   * サインイン監視用Subscription
   *
   * @private
   * @type {Subscription}
   * @memberof ExpDetailType1Component
   */
  private onNextLinkChanged: Subscription;

  /**
   * template
   *
   * @memberof ExpDetailType1Component
   */
  template: {
    /**
     * parts-header
     *
     * @type {ListParts}
     * @memberof ExpDetailType1Component
     */
    header: ListParts;

    /**
     *
     *
     * @type {ListParts}
     */
    body: ListParts[];

    /**
     * parts-footer
     *
     * @type {ListParts}
     * @memberof ExpDetailType1Component
     */
    footer: ListParts;
  };

  /**
   * 表示中料金
   * 利用開始日時によって変わる
   *
   * @type {ExpService["prices"][number]}
   * @memberof ExpDetailType1Component
   */
  targetServicePrice: ExpService["prices"][number];

  /**
   * オプショングループ
   *
   * @type {ExpService["option_groups"]}
   * @memberof ExpDetailType1Component
   */
  optionGroups: ExpService["option_groups"] = [];

  /**
   * サービス情報
   *
   * @type {ExpService}
   * @memberof ExpDetailType1Component
   */
  service: ExpService;

  /**
   * ユーザ選択情報
   *
   * @type {common.ExpBill}
   * @memberof ExpDetailType1Component
   */
  expBill: ExpBill;

  /**
   * 予約数の初期リスト
   *    料金種別数の合計値が最大予約数以下になるように
   *    可変となるため元となる初期リストを保存しておく
   *
   * @type {ListParts["select_box"]["item"][]}
   * @memberof ExpDetailType1Component
   */
  orgReservNumber: ListParts["select_box"]["item"][];

  /**
   * 表示中リソース(空き日程欄)
   *
   * @type {ExpResourceAvailable['resources'][number]}
   * @memberof ExpDetailType1Component
   */
  targetResource: ExpResourceAvailable['resources'][number] = {
    sg_resource_id: null,
    availables: [],
    name: ""
  };

  /**
   *
   *
   * @type {ExpResourceAvailable['resources']}
   * @memberof ExpDetailType1Component
   */
  resourceAvailable: ExpResourceAvailable['resources'] = [];

  /**
   * datePickerの表示範囲
   *  start: 現在日時
   *  end: 現在日時 + 2か月
   *  endTime: end + 7日(終了日時は2か月+7であるため)
   *
   * @memberof ExpDetailType1Component
   */
  datePickerRange = {
    start: new Date(),
    end: new Date(),
    endTime: new Date(),
  };


  /**
   * 選択中の日付
   * note: selectedDateのsetter以外での参照は禁止
   *
   * @private
   * @type {string}
   * @memberof ExpReserveListComponent
   */
  selectedDate: string;

  hourMinuteList: {
    view: string;
    hour: number;
    minute: number;
  }[] = [];

  /**
   * 選択中の日付
   *
   * @type {string}
   * @memberof ExpReserveListComponent
   */
  // get selectedDate(): string {
  //   return this._selectedDate;
  // }

  /**
   * モビリティ共通の利用開始日時が設定済みかどうか
   * true: 設定の必要あり & 未設定
   * false: 設定の必要なし or 設定済み
   *
   * @type {boolean}
   * @memberof ExpDetailType1Component
   */
  isSettedMobilityStartDate: boolean = false;

  /**
   * 表示中カレンダー週
   * 営業時間
   *
   * @type {ShopCalendar}
   * @memberof ExpDetailType1Component
   */
  businessHours: ShopCalendar[];

  /**
   * カレンダーに表示する空き情報を示した文字列
   *
   * @memberof ExpDetailType1Component
   */
  readonly available = {
    OK: "〇",
    NG: "×"
  }

  /**
   * カレンダーの次週・翌週判定に使用
   *
   * @memberof ExpDetailType1Component
   */
   readonly calendarWeekType = {
    next: "翌週",
    prev: "前週"
  } 

  /**
   *
   *
   * @type {({start: Date | moment.Moment, end: Date | moment.Moment})}
   * @memberof ExpDetailType1Component
   */
  businessHourRange: {start: Date | moment.Moment, end: Date | moment.Moment}

  /**
   * 取得済み 営業時間
   * 営業時間の重複取得を避ける為
   *
   * @private
   * @type {ShopCalendar[]}
   * @memberof ExpDetailType1Component
   */
  private gettedBusinessHour: ShopCalendar[] = [];

  // testtest: any = [];

  validationState: ValidationState = {
    err_start: { check: false },
    err_end: { check: false },
    err_start_end: { check: false }
  };

  validRange = {
    start: moment().format(moment.HTML5_FMT.DATE),
    end: moment().add(6, 'months').add(1, 'days').format(moment.HTML5_FMT.DATE)
  } 


  /**
   * mat-date-picker
   *
   * @type {MatDatepicker<undefined>}
   * @memberof ExpDetailType1Component
   */
  @ViewChild('matDatePicker') matDatePicker: MatDatepicker<undefined>;

  /**
   * calendar
   *
   * @type {FullCalendarComponent}
   * @memberof ExpDetailType1Component
   */
  @ViewChild('calendar') calendar: FullCalendarComponent;

  /**
   * 次画面への遷移ボタン
   *
   * @type {ElementRef}
   * @memberof ExpDetailType1Component
   */
  @ViewChild('btn_trans') btn_trans: ElementRef;

  /**
   * ons-back-button
   *
   * @private
   * @type {ElementRef}
   * @memberof ExpDetailType1Component
   */
  @ViewChild('onsBackButton') private onsBackButton: ElementRef;

  /**
   * オプション、インフォメーションマーク要素
   *
   * @type {QueryList<ElementRef>}
   * @memberof ExpDetailType1Component
   */
  @ViewChildren('optionDialog') optionDialog: QueryList<ElementRef>;

  /**
   * 利用開始日時ラベル
   *
   * @type {ElementRef}
   * @memberof ExpDetailType1Component
   */  
  @ViewChild('startTimeLabel') startTimeLabel: ElementRef;

  /**
   * matDatePicker
   * 利用開始日時
   *
   * @type {(MatDatepicker<Date | null> | undefined)}
   * @memberof ExpDetailType1Component
   */
  @ViewChild('startTime') matStart: MatDatepicker<Date | null> | undefined;

  /**
   * matDatePicker
   * 利用終了日時
   *
   * @type {(MatDatepicker<Date | null> | undefined)}
   * @memberof ExpDetailType1Component
   */
  @ViewChild('endTime') matEnd: MatDatepicker<Date | null> | undefined;

  /**
   * calendar plugins
   *
   * @memberof ExpDetailType1Component
   */
  calendarPlugins = [timeGridPlugin, interactionPlugin];

  /**
   * カレンダー
   *
   * @type {CalendarOptions}
   * @memberof ExpDetailType1Component
   */
  readonly calendarOptions: CalendarOptions = {

    // 種類
    initialView: "todayAsFirstDayOfWeek",
    plugins: [
      timeGridPlugin,      // Display events on Month view or DayGrid view
      interactionPlugin   // Provides functionality for event drag-n-drop, resizing, dateClick, and selectable actions
    ],
    views:{
      todayAsFirstDayOfWeek: {
        type: 'timeGridWeek',
        duration: {days: 7}
      }
    },
    validRange: this.validRange,    
    // タイトル
    titleFormat: {
      month: "long",
      day: "numeric"
    },
    // 終日OFF
    allDaySlot: false,
    // ツールバー
    headerToolbar: {left: 'today,prev,next', center: 'title', right: 'selectDate'},
    // ボタン
    buttonText: { today: "今日" },
    // ボタンヒント
    buttonHints: {
      prev: '前の週を表示',
      next: '次の週を表示',
      today: '今日の日付に戻る'
    },
    // 言語
    locale: 'ja',
    // リンクオフ
    navLinks: false,
    // 高さ(height)
    height: 650,

    // scrollTime: '09:00:00',


    // データセル
    dayCellContent: (date: DayCellMountArg) => {
      date.dayNumberText = date.dayNumberText.replace('日', '');  // カレンダーセルの「日」を除去
    },
    // データセルクリック　※空き情報はイベントで表示するため処理なし
    dateClick: (date: DateClickArg): void => {
      // this.selectedDate = moment(date.dateStr).format(moment.HTML5_FMT.DATE);  // 選択日の設定
      // this.selectedStartTime = moment(date.dateStr).format(moment.HTML5_FMT.TIME);
      // console.log(this.selectedStartTime);
    },
    // 日付ヘッダー
    dayHeaderFormat: function (date) {
      const day = date.date.day;
      const weekNum = date.date.marker.getDay();
      const week = ['(日)', '(月)', '(火)', '(水)', '(木)', '(金)', '(土)'][weekNum];
      return day + '\n' + week;
    },
    // カスタムボタン
    customButtons: {
      // date-picker
      selectDate: {
        hint: '日付を選択',
        click: () => {
          this.openDatePicker();
        }
      },
      // prev
      prev: {
        text: '<',
        hint: '前の週を表示',
        click: () => {
          this.setCalendarWeekChange(this.calendarWeekType.prev);
        }
      },
      // next
      next: {
        text: '>',
        hint: '次の週を表示',
        click: () => {
          this.setCalendarWeekChange(this.calendarWeekType.next);
        }
      },      
      // today
      today: {
        text: '今日',
        hint: '今日の日付に戻る',
        click: () => {
          const date_today = moment().format(moment.HTML5_FMT.DATE);
          this.setSelectedDate(date_today);  // 今日の日付に設定
        }
      }
    },
    // NOTE: slotmaxtime slotmintimeで指定した表示するスクロール領域
    //     の一番上からスクロールする距離を指定。
    //     slotmaxtime slotmintimeを指定した際にscrollToTimeが効かなかった為、初期表示時のoptionで指定。

    // slot
    slotLabelFormat: { hour: 'numeric', minute: 'numeric' },
    slotLabelInterval: "00:30",

    // event
    eventShortHeight: 100,
    eventMinHeight: 10,
    displayEventTime: false,
    eventBackgroundColor: "#ffffff",
    eventBorderColor: "#000000",
    eventTextColor: "#000000",

    // event click
    eventClick: (arg: EventClickArg): void => {

      // // 空き以外は処理しない
      // if (arg.el.textContent !== this.available.OK) return;

      // // 色初期化
      // const eve = document.getElementsByClassName("fc-event");
      // for (let i = 0; i < eve.length; i++) {
      //   if (eve.item(i).textContent === this.available.OK) eve.item(i).setAttribute("style", "background-color: #ffffff; border-color: #000000");
      // }

      // // 利用開始時間、終了時間
      // this.expBill.start_time = arg.event.start.toISOString();
      // const endtime = new Date(arg.event.start);
      // endtime.setMinutes(endtime.getMinutes() + this.expBill.util_time);
      // this.expBill.end_time = endtime.toISOString();

      // // 選択イベントの色変更
      // arg.el.style.backgroundColor = "skyblue";

      // // 遷移ボタン表示切替
      // this.switchOverTransitionBtn();

      // カレンダーの見方 ダイアログを開く
      this.openAvailableDescriptionDialog();
    },
  };

  /**
   * 設定情報(体験サービス)
   *
   * @type {Settings["exp"]}
   * @memberof ExpDetailType1Component
   */
  settingExp: Settings["exp"];

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof ExpDetailType1Component
   */
  subscription = new Subscription();

  /**
   * 説明欄(表示用)
   *
   * @type {string}
   * @memberof ExpDetailType1Component
   */
  dispDescription: string;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ExpDetailComponent.
   * @param {ApplicationMessageService} appMsgServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {UserWebApiService} userServ
   * @param {ExpWebApiService} expServ
   * @param {CommonFunctionModule} commonFunc
   * @param {OnsNavigator} navigator
   * @param {Params} params
   * @param {PagerService} pagerServ
   * @param {TabbarComponent} tabbarComp
   * @memberof ExpDetailType1Component
   */
  constructor(
    private appMsgServ: ApplicationMessageService,
    private errResServ: HttpErrorResponseParserService,
    private userServ: UserWebApiService,
    private expServ: ExpWebApiService,
    private commonFunc: CommonFunctionModule,
    private navigator: OnsNavigator,
    private params: Params,
    private pagerServ: PagerService,
    private signinServ: SigninService,
    private tabbarComp: TabbarComponent,
    private dialog: MatDialog,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
    private gtmServ: GoogleTagManagerService,
    private datePickerService: DatePickerService,
    private cdr: ChangeDetectorRef,
  ) {
    const settingsChanged = this.municipalityWebApiServ.settingsChanged.subscribe({
      next: setting => {
        if (setting == null) return;
        this.settingExp = setting.exp;
      }
    });
    this.subscription.add(settingsChanged);
  }

  /**
   * 初期化処理。
   *
   * @memberof ExpDetailType1Component
   */
  ngOnInit(): void {
  
    // カレンダーの最大表示日数（自治体毎の設定値Nヵ月後まで）
    this.datePickerRange.end.setMonth(this.datePickerRange.end.getMonth() + this.settingExp.reservation.max_month);

    // 終了日時として設定可能な日数
    this.datePickerRange.endTime.setFullYear(this.datePickerRange.end.getFullYear());
    this.datePickerRange.endTime.setMonth(this.datePickerRange.end.getMonth());
    this.datePickerRange.endTime.setDate(this.datePickerRange.endTime.getDate() + this.settingExp.service.max_reserve_hour / 24);

    // タブバー非表示
    this.tabbarComp.setTabbarVisibility(false);

    // サービス情報
    this.service = this.params.data.service;

    // 料金（初期値は当日）
    this.targetServicePrice = this.expServ.getTargetPrice(this.service.prices, moment());

    if (this.service.option_groups) {
      // オプショングループ順ソート
      this.optionGroups = this.service.option_groups.sort((a, b) => {
        return a.index - b.index;
      });

      // グループ一つ目からさらにグループ内ソート
      this.optionGroups.forEach(group => {
        group.options.sort((a, b) => {
          return a.index - b.index;
        });
      });
    }

    // 初期値
    this.initialExpBill();

    // 時刻リスト作成
    let target: moment.Moment = this.moment().startOf('date');
    while (target.isSame(this.moment(), 'day')) {
      this.hourMinuteList.push({view: target.format('H:mm'), hour: target.hour(), minute: target.minute()});
      target.add(30, 'm');
    }

    this.dispDescription = this.commonFunc.replaceUrl(this.commonFunc.replaceEscapeSequence(this.service.description));

    /**
     *
     * 画面表示list作成
     *
     */
    this.template = {
      header: {
        mode: "header",
        header_list: {
          item: [this.service.title, "提供：" + this.service.shop.name],
          icon: this.service.images[0] ?? ""
        }
      },
      body: [],
      footer: {
        header: 'キャンセル料ルール',
        common: { item: this.expServ.getDispCancelRules(this.targetServicePrice.cancel_rules) }
      }
    };

    // const viewDate = this.expServ.createViewDate(this.service, this.expBill.price);
    // this.template.body = viewDate.parts;
    // this.orgReservNumber = viewDate.orgReservNumber;

    // リストデータ生成
    const params = this.service.user_params;
    /* オプション */
    /** 予約数 @type {*} */
    let reserv_number: ListParts["select_box"]["item"][] = [];
    /** 貸出場所 @type {*} */
    let p_rental: ListParts["select_box"]["item"] = [];
    /** 返却場所 @type {*} */
    let p_return: ListParts["select_box"]["item"] = [];
    /** 開催場所 @type {*} */
    let p_place: ListParts["select_box"]["item"] = [];
    /** 利用時間 @type {*} */
    // let util_time: ListParts["select_box"]["item"] = [];
    let resource: ListParts["select_box"]["item"] = [];
    // 利用時間
    // params.util_time.select.forEach(n => util_time.push({ key: n, name: this.commonFunc.TimeConvert(n) }));

    // モビリティ
    if (this.service.service_type === "MOBILITY") {
      // 貸出場所、返却場所
      params.rental_place.select.forEach(p => p_rental.push({ key: p.name, name: p.name, link: this.commonFunc.getGoogleMapUrl(p.location) }));
      params.return_place.select.forEach(p => p_return.push({ key: p.name, name: p.name, link: this.commonFunc.getGoogleMapUrl(p.location) }));
    }
    // アクティビティ
    else if (this.service.service_type === "ACTIVITY") {
      // 開催場所
      params.place.select.forEach(p => p_place.push({ key: p.name, name: p.name, link: this.commonFunc.getGoogleMapUrl(p.location) }));
    }
    else ;

    // 予約数、料金
    params.numbers.forEach((n, index: number) => {
      reserv_number.push([]);

      // 初期値
      this.expBill.numbers.push({type: n.type, value: n.select[0]});

      // for (let i = 1; i <= this.service.capacity; i++) {
      //   if (i === 0 && params.numbers.length >= 2) reserv_number[index].push({ key: "0", name: "0" + n.unit });
      //   reserv_number[index].push({ key: i, name: i.toString() + n.unit });
      // }
      n.select.forEach(p => reserv_number[index].push({ key: p, name: p.toString() + n.unit }));

      let price: string = "";
      // if (params.numbers.length >= 2) price += n.name + "：";
      price += this.commonFunc.numericalForming(this.expBill.price[index].value) + "/" + this.expBill.unit;
    });
    // 料金計算
    // this.calcuPrice();
    // let viewPrice = params.numbers.map((n, index) => {
    //   let price: string = "";
    //   if (params.numbers.length >= 2) price += n.name + "：";
    //   price += this.commonFunc.numericalForming(this.expBill.price[index].value) + "/" + this.expBill.unit;
    //   return price;
    // });

    // オリジナル情報を保存
    this.orgReservNumber = this.commonFunc.deepcopy(reserv_number);

    // 料金種別数
    const length = this.service.user_params.numbers.length;

    if (this.service.service_type === "MOBILITY") {
      this.template.body.push({
        displayOrder: 3,
        id: "rental",
        mode: p_rental.length > 1 ? "selectBox" : "common",
        header: "貸出場所",
        select_box: {
          item: p_rental,
          is_link: true,
          select_id: "rental"
        },
        common: {
          item: p_rental[0].name,
          link: p_rental[0].link
        },
      },
      {
        displayOrder: 4,
        id: "return",
        mode: p_return.length > 1 ? "selectBox" : "common",
        header: "返却場所",
        select_box: {
          item: p_return,
          is_link: true,
          select_id: "return"
        },
        common: {
          item: p_return[0].name,
          link: p_return[0].link
        },
        valid_msg: ""
      },
      {
        displayOrder: 2,
        id: "reserv_number",
        mode: reserv_number[0].length > 1 ? "selectBox" : "common",
        header: "予約数",
        select_box: {
          item: reserv_number[0],
          select_id: "reserv_number",
        },
        common: {
          item: reserv_number[0][0].name ?? "",
        }
      });
    }
    else if (this.service.service_type === "ACTIVITY") {
      this.template.body.push({
        displayOrder: 3,
        id: "place",
        mode: p_place.length > 1 ? "selectBox" : "common",
        header: "開催場所",
        select_box: {
          item: p_place,
          is_link: true,
          select_id: "place"
        },
        common: {
          item: p_place[0].name,
          link: p_place[0].link
        },
      });

      reserv_number.forEach((r, index: number) => {
        this.template.body.push({
          displayOrder: 2,
          id: "reserv_number" + index,
          mode: "selectBox",
          select_box: {
            item: r,
            select_id: "reserv_number" + index
          }
        });

        // 料金種別が複数ある場合のみ種別名を表示
        if (length >= 2) this.template.body[this.template.body.length - 1].select_box.type_name = this.service.user_params.numbers[index].name;

        // todo　capacityの単位をどうだすか（10「人」の部分）
        if (index === 0) {
          this.template.body[this.template.body.length - 1].header = this.service.capacity ? "予約数" + (length > 1 ? "（最大：" + this.service.capacity + this.service.user_params.numbers[index].unit + "）" : "") : "予約数";
        }
      });
    }

    // 表示順にソート
    this.template.body.sort((a, b) => a.displayOrder - b.displayOrder);

    this.gtmServ.pushExpDetailPageview(this.service);
  }

  /**
   * afterViewInit
   *
   * @memberof ExpDetailType1Component
   */
  ngAfterViewInit(): void {

    // 詳細画面からサインインを求められた場合、サインイン後にほかのタブへ遷移する可能性がある。
    // 詳細画面は下部タブが非表示のため、タブを再表示させる。
    // ほかのタブから詳細画面（利用するタブをタップ）に戻ってきた際にはタブを再び非表示にする。
    // ◆実装手段
    // ①詳細画面遷移時ページスタックインデックスを保存しておく
    // ②ほかのタブへ遷移時に下部タブを再表示（signinComponent、AccountListComponent）
    // ③利用するタブタップ時、現画面が観光詳細の場合（①のインデックスから判断）、下部タブを非表示（TabbarCompoenntのngAfterViewInitに記述）
    // ④詳細画面から次の画面へ遷移する前に①のインデックスを破棄しておく
    this.pagerServ.setReturnPage({ index: this.navigator.element.pages.length - 1, key: PageKey.ExpDetailComponent });

    // オプション、インフォメーションマークclick時、二重イベント止める
    this.optionDialog.forEach(item => {
      item.nativeElement.addEventListener('click', (event) => {
        console.log('click');
        event.stopPropagation();
      });
    })

    // バックボタン
    this.onsBackButton.nativeElement.onClick = () => {

      // タブバー再表示
      this.tabbarComp.setTabbarVisibility(true);

      // 詳細画面のページインデックスが不要となるため、削除しておく
      this.pagerServ.deleteReturnPage(PageKey.ExpDetailComponent);

      const isUpdate: boolean = this.params.data.updated===true ? true : false;
      if (isUpdate) {
        if (this.params.data.isLink) {
          // popPage
          this.pagerServ.transitionToPage(this.navigator, PageKey.ExpComponent);
        }
        else this.pagerServ.transitionToPage(this.navigator, PageKey.ExpServiceListComponent);
      }
      else {
        // popPage
        this.navigator.element.popPage().then(() => {
          // NOTE: モビパからの遷移の場合、トップ画面に戻る
          // 観光トップ画面のswiper.jsを初期化
          if (this.params.data.isLink) this.params.data.swiperInit();
        });
      }
    };

    // サインイン処理を監視
    this.onNextLinkChanged = this.signinServ.changed.subscribe({
      next: (leaveSignin: boolean) => {
        if (leaveSignin === false) {
          // イベント、ボタン初期化
          this.calendar.getApi().removeAllEvents();
          this.initial();
          this.targetResource.availables.splice(0);
          this.communicationsCommand(true);
        }
      }
    });

    this.initial();

    // カスタムボタンにカレンダーアイコンを挿入
    this.calendar.getApi().el.querySelector('button.fc-selectDate-button')?.insertAdjacentHTML('beforeend', '<span class="material-icons calendar-icon">calendar_month</span>');

    // 今日ボタンを非活性
    if(this.expBill.start.date && (this.expBill.start.date != moment().format('YYYY-MM-DD'))){
      this.calendar.getApi().el.querySelector<HTMLButtonElement>('.fc-today-button').disabled = false;
    }else{
      this.calendar.getApi().el.querySelector<HTMLButtonElement>('.fc-today-button').disabled = true;
    }

    // カレンダー スワイプ処理の設定
    var detector = ons.GestureDetector(document.querySelector('#week-calendar'), {swipeVelocityX: 0.1});
    detector.on('swipeleft', ()=> {
      this.setCalendarWeekChange(this.calendarWeekType.next);
    });

    detector.on('swiperight', ()=> {
      this.setCalendarWeekChange(this.calendarWeekType.prev);
    });

    setTimeout(() => {
      // 初期表示で利用開始日時を設定しない場合のみ営業時間・空き情報を取得
      //（利用開始日時を設定する場合、二重取得となってしまうため）
      if(!this.params.data.from_period_search || this.expBill.start.date == ''){
        // 営業時間、空き情報取得
        this.communicationsCommand(true);
      }

      // 貸出、返却場所チェック
      this.checkIsDropOff();

      // 最少人数チェック
      if (this.service.service_type === "ACTIVITY") {

        const sumNum = this.expBill.numbers.map(n => n.value).reduce((a, b) => a + b);
        const index = this.template.body.findIndex(b => b.id === "reserv_number" + (this.service.user_params.numbers.length - 1));
        // console.log("index: ", index, "sum: ", sumNum,  "this.template.body: ", this.template.body);

        // いずれかの利用数が未選択
        if (this.expBill.numbers.filter(n => n.value < 0).length !== 0) this.template.body[index].valid_msg = "すべて選択してください。";
        else {
          if (this.expBill.err_min_number = this.expServ.isMinNumberCheck(this.service.constraints, sumNum))
            // todo 表示するメッセージは制約によって切り替わる。現状は配列[0]に最小人数制約が入っている前提
            this.template.body[index].valid_msg = this.service.constraints[0].message;
          else this.template.body[index].valid_msg = "";
        }
      }

      // 日数選択型オプションチェック
      this.checkAllTimeOption();

      // 遷移ボタン表示切り替え
      this.switchOverTransitionBtn();
    }, 0);
  }

  /**
   * 日付選択ボタンをクリックしたときのイベントハンドラ
   */
  private openDatePicker() {
    this.matDatePicker.open();

    setTimeout(() => {
      this.datePickerService.positionDatePicker();
    }, 0);
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  /**
   * 破棄処理。
   *
   * @memberof ExpDetailType1Component
   */
  ngOnDestroy(): void {

    this.busy?.unsubscribe();
    this.onNextLinkChanged?.unsubscribe();
    this.subscription?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * datePickerで(空き日程)日付選択時のイベントハンドラ
   *
   * @param {MatDatepickerInputEvent<Date>} event
   * @memberof ExpReserveListComponent
   */
  onChangeTargetDate(event: MatDatepickerInputEvent<Date>): void {

    const selected = moment(event.value).format(moment.HTML5_FMT.DATE);
    this.setSelectedDate(selected);  // 選択日を設定
  }

  /**
   * datePickerで(開始, 終了日時)日付選択時のイベントハンドラ
   *
   * @param {MatDatepickerInputEvent<Date>} event
   * @memberof ExpDetailType1Component
   */
  onChangeDate(event: MatDatepickerInputEvent<Date>, type: 'start' | 'end'): void {

    if (event.value === void 0 || event.value === null) return;

    let target: moment.Moment;

    if (type === 'start') {
      this.expBill.start.date = event.value.toISOString();
      target = this.expBill.start.time.view === "" ? this.moment(this.expBill.start.date) : this.moment(this.expBill.start.date).set({'hour': this.expBill.start.time.hour, 'minute': this.expBill.start.time.minute});
    }
    else {
      this.expBill.end.date = event.value.toISOString();
      target = this.expBill.end.time.view === "" ? this.moment(this.expBill.end.date) : this.moment(this.expBill.end.date).set({'hour': this.expBill.end.time.hour, 'minute': this.expBill.end.time.minute});
    }

    if (type === 'start') {
      this.setSelectedDate(this.expBill.start.date);
    }
    else {
      this.checkStartEnd(type);
    }

    // 日数選択型オプションチェック
    this.checkAllTimeOption();

    // 遷移ボタン表示切り替え
    this.switchOverTransitionBtn();
  }

  /**
   *
   *
   * @param {{view: string, hour: number, minute: number}} time
   * @param {('start' | 'end')} type
   * @memberof ExpDetailType1Component
   */
  onChangeTime(time: {view: string, hour: number, minute: number}, type: 'start' | 'end'): void {

    if (type == 'start') this.expBill.start.time = time;
    else this.expBill.end.time = time;

    this.checkStartEnd(type);

    // 日数選択型オプションチェック
    this.checkAllTimeOption();

    // 遷移ボタン表示切り替え
    this.switchOverTransitionBtn();
  }

  /**
   * 空き日程 リソース変更
   *
   * @memberof ExpDetailType1Component
   */
  onChangeResource(resource: ExpResourceAvailable['resources'][number]): void {
    // 表示リソース変更
    this.targetResource = resource;

    // イベント、ボタン初期化
    this.calendar.getApi().removeAllEvents();

    // ボタン追加
    this.addCustomEventSource();
  }

  /**
   * オプション変更(user_option: yesno)
   *
   * @param {ExpService["option_groups"][number]["options"][number]} option
   * @param {(SgServiceOption["yesno_param"]["items"][number] | SgServiceOption["select_param"]["items"][number])} item
   * @memberof ExpDetailType1Component
   */
  onChangeOptionYesno(option: ExpService["option_groups"][number]["options"][number], item: SgServiceOption["yesno_param"]["items"][number]): void {
    this.expBill.options[option.sg_option_id+""].yesno_param = item;
    this.checkTimeOption(option);
    this.switchOverTransitionBtn();
  }

  /**
   * オプション変更(user_option: select)
   *
   * @param {ExpService["option_groups"][number]["options"][number]} option
   * @param {SgServiceOption["select_param"]["items"][number]} item
   * @memberof ExpDetailType1Component
   */
  onChangeOptionSelect(option: ExpService["option_groups"][number]["options"][number], item: SgServiceOption["select_param"]["items"][number]): void {
    this.expBill.options[option.sg_option_id+""].select_param = item;
  }

  /**
   *
   *
   * @param {ExpService["option_groups"][number]["options"][number]} option
   * @param {number} value
   * @memberof ExpDetailType1Component
   */
  onChangeOptionNumber(option: ExpService["option_groups"][number]["options"][number], value: number): void {
    this.expBill.options[option.sg_option_id+""].number_param.selected = value;
  }

  /**
   * 日数選択型オプション 変更
   *
   * @param {ExpService["option_groups"][number]["options"][number]} option
   * @param {number} item
   * @memberof ExpDetailType1Component
   */
  onChangeOptionTime(option: ExpService["option_groups"][number]["options"][number], item: number) {

    this.expBill.options[option.sg_option_id+""].time_param.selected = item;
    this.checkTimeOption(option);
    this.switchOverTransitionBtn();
  }

  /**
   * 次画面へ遷移する。
   *
   * @memberof ExpDetailType1Component
   */
  onTransition(): void {

    // 請求先がない（未ログイン）場合、遷移せずログイン促し
    if (false === this.userServ.isLoggedIn()) {
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_UNLOGIN_USE_FUNCTION.message(), () => {

        this.pagerServ.setUserAppNavigator = true;
        this.pagerServ.getAppNavigator.element.pushPage(SigninComponent, { data: { exp: true, redraw: () => {

          // // イベント、ボタン初期化
          // this.calendar.getApi().removeAllEvents();
          // this.initial();
          // this.communicationsCommand(true);
        }}});
      });

      return;
    }

    // 詳細画面のページインデックスが不要となるため、削除しておく
    this.pagerServ.deleteReturnPage(PageKey.ExpDetailComponent);

    const start = moment(this.expBill.start.date).set({'hour': this.expBill.start.time.hour, 'minute': this.expBill.start.time.minute});
    const end = moment(this.expBill.end.date).set({'hour': this.expBill.end.time.hour, 'minute': this.expBill.end.time.minute});

    const setOption = () => {
      let optionBody: request.ExpReservation["service_options"] = [];

      // 開始終了日時をmoment型に設定

      this.service.option_groups.forEach(group => {
        group.options.forEach(option => {
          const targetBill: UserSelectOption = this.expBill.options[option.sg_option_id];
          let optionItem: request.ExpReservation["service_options"][number] = {
            sg_option_id: option.sg_option_id,
            sg_option_price_id: this.expServ.getTargetOptionPrice(option.prices, start).sg_option_price_id,
          }

          if (option.user_option) optionItem.user_option = {};
          switch(option.user_option) {
            case 'comment':
              optionItem.user_option.comment = targetBill.comment;
              break;
            case 'yesno':
              optionItem.user_option.yesno = targetBill.yesno_param.value;
              break;
            case 'select':
              optionItem.user_option.select = targetBill.select_param.value;
              break;
            case 'number':
              optionItem.user_option.number = targetBill.number_param.selected;
              break;
            default:
              console.log("想定外");
          }

          // 時間選択
          if (option.time_rule !== 'none') optionItem.time_option = {};
          switch(option.time_rule) {
            case 'days':
              // yesno型オプション&「希望しない」を選択時、daysを0に
              if (option.user_option === 'yesno' && targetBill.yesno_param.value === false) optionItem.time_option.days = 0;
              else optionItem.time_option.days = targetBill.time_param.selected;
              break;
            // case 'mins':
            //   targetOption.time_option.min = target.time_param.selected;
            //   break;
          }

          optionBody.push(optionItem);
        })
      })
      return optionBody;
    }

    // fetch用リクエストボディ作成
    let reqBody: request.ExpReservation = {
      sg_service_id: this.service.sg_service_id,
      numbers: this.expBill.numbers,
      // util_time: this.expBill.util_time,
      start_time: start.toString(),
      end_time: end.toString(),
      sg_price_id: this.expServ.getTargetPrice(this.service.prices, moment(start)).sg_price_id,
      service_options: setOption()
    };

    // モビリティ 貸出、返却場所
    if (this.service.service_type === "MOBILITY") {
      reqBody.rental_place = this.expBill.rental_place;
      reqBody.return_place = this.expBill.return_place;
    }
    // アクティビティ 開催場所
    else if (this.service.service_type === "ACTIVITY") {
      reqBody.place = this.expBill.place;
    }

    // fetch
    this.busy = this.expServ.postFetchReservation(reqBody).subscribe({
      next: res => {

        const body: ExpFetchReservation = res.body;
        // 次画面へ
        this.navigator.element.pushPage(ExpConfirmComponent, {
          data: {
            fetch: body,
            service: this.service,
            expBill: {
              ...this.expBill,
              start_time: start,
              end_time: end
            },
            updateSchedule: (updateSchedule: boolean, updateService: boolean=false) => {this.backToDetail(updateSchedule, updateService)}
          }
        });
      },
      error: this.errResServ.doParse((_err, errContent) => {

        switch(errContent.smartGotoErrCode) {
          case this.appMsgServ.SERV_CONST_CODE.SG_OPTION_PRICE.CALC_FAILED:
            const errOption: {sg_option_id: number, param: "number"|"days"|"mins", table_limit: number} = errContent.smartGotoErrOpt;
            let optionName: string = void 0;
            this.service.option_groups.some(group => {
              const result = group.options.find(option => option.sg_option_id === errOption.sg_option_id);
              if (result === void 0) return false;
              optionName = result.name;
              return true;
            });
            this.errResServ.viewErrDialog(errContent, optionName);
            break;
          case this.appMsgServ.SERV_CONST_CODE.SG_OPTION.UNMATCH_REQUEST:
            this.appMsgServ.viewDialogMessage(this.msg.CLIENT.EXP.OPTION_UNMATCH_REQUEST.message(), () => {
              this.updateService();
            });
            break;
          default:
            this.errResServ.viewErrDialog(errContent);
        }
      })
    });
  }

  /**
   * セレクトボックス変更時の値格納処理。
   *
   * @param {*} event
   * @memberof ExpDetailType1Component
   */
  selectedEvent(event: any): void {

    switch (event.id) {
      // 貸出場所
      case "rental":
        this.expBill.rental_place = this.service.user_params.rental_place.select.find(f => f.name === event.key);
        this.checkIsDropOff();
        break;
      // 返却場所
      case "return":
        this.expBill.return_place = this.service.user_params.return_place.select.find(f => f.name === event.key);
        this.checkIsDropOff();
        break;
      // 開催場所
      case "place":
        this.expBill.place = this.service.user_params.place.select.find(f => f.name === event.key);
        break;
      // 利用数、人数
      case "reserv_number":
        const key = Number(event.key);

        // リストに変更がないなら終了
        if (this.expBill.numbers[0].value ===  key) return;

        // 選択値を保存
        this.expBill.numbers[0].value = key;
        break;
      default:
        if (!event.id.indexOf("reserv_number")){

          // 対象の要素番号（２桁（最大９９=length１００）まで対応）を取得
          const typeIndex: number = Number((<string>event.id).slice(this.expBill.numbers.length >= 10 ? -2 : -1));

          /** このイベントによって入力された数 */
          const key = Number(event.key);

          // リストに変更がないなら終了
          if (this.expBill.numbers[typeIndex].value === key) return;

          // 選択値を保存
          this.expBill.numbers[typeIndex].value = key;

          if (this.service.capacity !== void 0) {
            // 操作した料金種別以外の予約数の上限値を設定
            let reserv_numbers = this.template.body.filter(b => b?.id != undefined && !b?.id.indexOf("reserv_number"));
            /** 現在の全リソースの合計選択数 */
            const counts = this.expBill.numbers.reduce((pre,cur) => pre+cur.value,0);
            reserv_numbers.forEach((n, index: number) => {

              // (最大予約数 - 現在の合計選択数 + このリソースの選択数)を上限としたものに変更
              n.select_box.item = this.commonFunc.deepcopy(this.orgReservNumber[index].filter(item => (this.service.capacity - counts + this.expBill.numbers[index].value) >= Number(item.key)));
            });
          }

          // 最少人数チェック
          if (this.service.service_type === "ACTIVITY") {

            const sumNum = this.expBill.numbers.map(n => n.value).reduce((a, b) => a + b);
            const index = this.template.body.findIndex(b => b.id === "reserv_number" + (this.service.user_params.numbers.length - 1));
            // console.log("index: ", index, "sum: ", sumNum,  "this.template.body: ", this.template.body);

            // いずれかの利用数が未選択
            if (this.expBill.numbers.filter(n => n.value < 0).length !== 0) this.template.body[index].valid_msg = "すべて選択してください。";
            else {
              if (this.expBill.err_min_number = this.expServ.isMinNumberCheck(this.service.constraints, sumNum))
                // todo 表示するメッセージは制約によって切り替わる。現状は配列[0]に最小人数制約が入っている前提
                this.template.body[index].valid_msg = this.service.constraints[0].message;
              else this.template.body[index].valid_msg = "";
            }
          }
        }
        else return;
    }

    // イベント、ボタン初期化
    // this.calendar.getApi().removeAllEvents();
    // this.initial();


    // 取得済みの空き情報を使ってカレンダーを更新
    // this.addCustomEventSource();

    // 遷移ボタン表示切り替え
    this.switchOverTransitionBtn();
  }

  /**
   * オプション詳細ダイアログ
   *
   * @param {ExpService["option_groups"][number]["options"][number]} option
   * @memberof ExpDetailType1Component
   */
  onClickOptionInfo(option: ExpService["option_groups"][number]["options"][number]): void {
    this.expServ.openOptionDialog(option);
  }

  /**
   * 空き日程 infoマークclick
   *
   * @memberof ExpDetailType1Component
   */
  onClickAvailableInfo(): void {
    this.openAvailableDescriptionDialog();
  }

//=============================================================================================
// メソッド
//=============================================================================================


  /**
   * 初期化。
   *
   * @memberof ExpDetailType1Component
   */
  initial(): void {

    const paramsFromPeriodSearch = this.params.data;

    if(paramsFromPeriodSearch.from_period_search){
      // 利用開始/終了時刻
      this.expBill.start = {
        date: paramsFromPeriodSearch?.start_date?.date ?? "",
        time: {view: paramsFromPeriodSearch?.start_date?.time?.view ?? "", hour: paramsFromPeriodSearch?.start_date?.time?.hour ?? null, minute: paramsFromPeriodSearch?.start_date?.time?.minute ?? null}
      };
      this.expBill.end = {
        date: paramsFromPeriodSearch?.end_date?.date,
        time: {view: paramsFromPeriodSearch?.end_date?.time?.view ?? "", hour: paramsFromPeriodSearch?.end_date?.time?.hour ?? null, minute: paramsFromPeriodSearch?.end_date?.time?.minute ?? null}
      };
      // 利用開始/終了日時未選択の場合
      if(this.expBill.start.date && this.expBill.end.date){
        this.matStart.select(new Date(this.expBill.start.date));
        this.matEnd.select(new Date(this.expBill.end.date));
      }else{
        // datePicker選択初期化
        this.matStart.select(null);
        this.matEnd.select(null);
      }
      
    }else{
      // datePicker選択初期化
      this.matStart.select(null);
      this.matEnd.select(null);
    }

    // エラー状態初期化
    this.validationState = {
      err_start: { check: false },
      err_end: { check: false },
      err_start_end: { check: false }
    }

    // 次画面遷移ボタン非表示
    this.btn_trans.nativeElement.style.display = "none";
  }

  /**
   * クライアント表示データ初期化
   *
   * @memberof ExpDetailType1Component
   */
  initialExpBill(): void {

    // const initNumber = (num: ExpOptionNumber): number => {

    //   if (num === undefined) return undefined;

    //   // let select: number[] = [];
    //   // num.select[0] === 0 ? select = num.select.splice(1) : select = num.select;
    //   return num.select ? Math.min(...num.select) : num.from
    // };

    const initPlace = (place: ExpOptionPlace): ExpPlace => {

      if (place === undefined || place.select === undefined || place.select.length === 0) return undefined;
      return place.select[0];
    };

    // オプション初期表示設定
    const initOption = (): void => {
      // オプション設定がない場合、処理をぬける
      if (!this.optionGroups) {
        this.expBill.options = null;
        return;
      }

      this.expBill.options = {};
      // サービスオプションをユーザー選択状態プロパティに変換
      this.optionGroups.forEach(group => {
        group.options.forEach(option => {
          let expBillOption: UserSelectOption = {};

          switch (option.user_option) {
            case 'yesno':
              // デフォルトプロパティが付与されている物を初期値に
              expBillOption.yesno_param = option.yesno_param.items.find(item => item.default === true);
              break;
            case 'select':
              // デフォルトプロパティが付与されている物を初期値に
              expBillOption.select_param = option.select_param.items.find(item => item.default === true);
              break;
            case 'number':
              let number: UserSelectOption["number_param"] = this.commonFunc.deepcopy(option.number_param);
              // セレクトボックス内容を指定されている場合
              if (number.select) {
                // 初期値
                number.selected = number.select[0];
                expBillOption.number_param = number;
              }
              else {
                number.select = [];
                // from, to, stepを元にセレクトボックス配列作成
                for (let num = number.from; num <= number.to; num += number.step) {
                  number.select.push(num);
                }
              }
              // 初期値
              number.selected = number.select[0];
              expBillOption.number_param = number;
              break;
            case 'comment':
              expBillOption.comment = "";
              break;
            default:
              console.log("想定外");
          }

          // 個数決定タイプを選択する必要ありの場合、利用日数or時間のセレクトボックス配列作成
          if (option.time_rule === 'days' || option.time_rule === 'mins') {

            expBillOption.time_param = option.time_param;
            expBillOption.time_param.select = [];

            // NOTE: 個数決定タイプごとの上限までセレクトボックスを作成。
            //        チェック関数で設定可能か確認　
            // from, to, stepを元にセレクトボックス配列作成
            const to: number = Math.min(Math.floor(this.settingExp.service.max_reserve_hour/24)+1, expBillOption.time_param.to);
            for (let num = expBillOption.time_param.from; num <= to; num += expBillOption.time_param.step) {
              if (num === 0) continue;
              expBillOption.time_param.select.push(num);
            }
            // 初期値
            expBillOption.time_param.selected = expBillOption.time_param.select[0];
          }
          // エラー
          expBillOption.err = {
            message: "",
            check: false,
          }
          expBillOption.time_err = {
            message: "",
            check: false
          };

          this.expBill.options[option.sg_option_id+""] = expBillOption;
        })
      })
    }

    const initRange = (range: ExpTimeRange): { from: string, to: string } => {

      if (range === undefined || !range.select.type || range.select?.type?.length === 0) return undefined;
      return range.select.type[0];
    };

    const params = this.service.user_params;
    this.expBill = {
      payer_name: "",

      // 場所
      rental_place: initPlace(params?.rental_place),
      return_place: initPlace(params?.return_place),
      place: initPlace(params?.place),

      // 乗り捨て不可エラー
      err_drop_off: false,

      // 最少人数エラー
      err_min_number: false,

      // 利用時間
      // util_time: initNumber(params?.util_time),

      // 利用数
      // number: initNumber(params?.numbers[0]),
      numbers: [],

      // 利用数単位,
      unit: params?.numbers[0]?.unit,

      // 利用可能時間帯
      time_range: initRange(params?.time_range),

      // 料金
      price: [],

      // start_time: "",

      start: {
        date: "",
        time: {view: "", hour: null, minute: null}
      },

      end: {
        date: "",
        time: {view: "", hour: null, minute: null}
      },

      // end_time: "",
    };

    // オプション初期値設定
    initOption();

    // if (this.service.service_type == 'ACTIVITY') {
    //   // 開始、終了時刻
    //   this.expBill.start_time = "";
    //   this.expBill.end_time = "";
    // }

    this.service.user_params.numbers.forEach((p, index: number) => {

      this.expBill.price.push({ name: p.name ?? "", value: 0 });
    });
  }

  /**
   * 開始日時、終了日時のバリデーションチェック
   *
   * @param {('start' | 'end')} type
   * @memberof ExpDetailType1Component
   */
  checkStartEnd(type?: 'start' | 'end'): void {

    const now: moment.Moment = this.moment();

    if (this.gettedBusinessHour.length === 0 && this.businessHours) this.gettedBusinessHour = this.businessHours;

    const check = (
      target: {
        date: string,
        time: {
          view: string,
          hour: number,
          minute: number}
      }
    ) => {
      const setErrMessage = (message: string = "") => {
        if (type === 'start') this.validationState.err_start = {check: true, message: message};
        else this.validationState.err_end = {check: true, message: message};
      }

      // 未入力の物がある場合、処理を抜ける
      if (target.date === '' || target.time.view === '') {
        setErrMessage();
        return;
      }
      const dateTime: moment.Moment = this.moment(target.date).set({'hour': target.time.hour, 'minute': target.time.minute});

      // 選択日が当日 & 現在時刻より前
      if (this.moment().isSame(dateTime, 'date') && !dateTime.isSameOrAfter(now.toString(), 'minute')) {
        // 現在時刻より前エラー出力

        setErrMessage("選択された日時は現在時刻前です。選択しなおしてください。");
        console.log("現在時刻より前");
        return;
      }

      // 営業時間内かどうか
      if (this.checkIncludeBusinessHour(dateTime) === false) {
        // 営業時間外エラー出力
        console.log("営業時間外");

        setErrMessage("選択された日時は営業時間外です。選択しなおしてください。");

        return;
      }
      else { if (this.service.service_type === 'MOBILITY' && type === 'start') this.expServ.mobilityStartDate = dateTime.format(moment.HTML5_FMT.DATETIME_LOCAL); }

      // 日付を変更した場合 or 変更後の営業時間を取得していない場合、営業時間を新たに取得
      if (this.gettedBusinessHour.length === 0 ||
        moment(this.gettedBusinessHour[0].date).startOf('week').format(moment.HTML5_FMT.DATE) !== dateTime.clone().startOf('week').format(moment.HTML5_FMT.DATE)) {
        // 営業時間取得
        this.busy = this.getShopCalendar(false, dateTime.toString()).subscribe({
          next: res => {
            this.gettedBusinessHour = res.body;
            // 営業時間内かどうか
            if (this.checkIncludeBusinessHour(dateTime, this.gettedBusinessHour) === false) {
              // 営業時間外エラー出力
              console.log("営業時間外");
              setErrMessage("選択された日時は営業時間外です。選択しなおしてください。");
              return;
            }
            else { if (this.service.service_type === 'MOBILITY' && type === 'start') this.expServ.mobilityStartDate = dateTime.format(moment.HTML5_FMT.DATETIME_LOCAL); }
          },
          error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
        })
      }
      else {
        // 営業時間内かどうか
        if (this.checkIncludeBusinessHour(dateTime, this.gettedBusinessHour) === false) {
          // 営業時間外エラー出力
          console.log("営業時間外");
          setErrMessage("選択された日時は営業時間外です。選択しなおしてください。");
          return;
        }
        else { if (this.service.service_type === 'MOBILITY' && type === 'start') this.expServ.mobilityStartDate = dateTime.format(moment.HTML5_FMT.DATETIME_LOCAL); }

      }
    }

    // 開始時刻終了時刻どちらもチェック
    if (!type) {
      this.checkStartEnd('start');
      this.checkStartEnd('end');
      return;
    }

    if (type === 'start') {
      this.validationState.err_start = { check: false, message: "" };
      check(this.expBill.start);
    }
    else {
      this.validationState.err_end = { check: false, message: "" };
      check(this.expBill.end);
    }

    this.validationState.err_start_end = { check: false, message: "" };

    // 未入力の物がある場合、処理を抜ける
    if (this.expBill.start.date === '' || this.expBill.end.date === '' ||
    this.expBill.start.time.view === '' || this.expBill.end.time.view === '') {
      this.validationState.err_start_end = { check: true, message: "" };
      return;
    }

    // 開始終了日時をmoment型に設定
    const start = moment(this.expBill.start.date).set({'hour': this.expBill.start.time.hour, 'minute': this.expBill.start.time.minute});
    const end = moment(this.expBill.end.date).set({'hour': this.expBill.end.time.hour, 'minute': this.expBill.end.time.minute});

    console.log(start.format("YYYY-MM-DD HH:mm") + "~" + end.format("YYYY-MM-DD HH:mm"));

    // 終了が開始より前
    if (start.isSameOrAfter(end.format("YYYY-MM-DD HH:mm"))) {
      this.validationState.err_start_end = {check: true, message: "終了日時は開始日時より後の日時を選択してください。"};
      return;
    }

    // 開始と終了の差が7日を超える
    if (end.diff(start, 'minutes') > this.settingExp.service.max_reserve_hour * 60) {
      this.validationState.err_start_end = {check: true, message: "7日以上の予約はできません。選択しなおしてください。"};
      return;
    }
  }

  /**
   * 選択中の日付を設定する。
   *
   * @param {string} date 設定する日付
   * @param {boolean} [updateData=true] 空き情報、営業時間を更新するかどうか
   * @memberof ExpDetailType1Component
   */
  setSelectedDate(date: string, updateData: boolean = true): void {

    console.log("setSelectedDate");

    if (moment(date).isValid()) {

      // 選択前の日付
      const old_date = this.selectedDate;
      // 選択後の日付
      const formatted_date = moment(date).format(moment.HTML5_FMT.DATE);
      // 選択日を変更
      this.selectedDate = formatted_date;

      if (old_date != formatted_date) {
        // カレンダー表示をクリア
        this.calendar.getApi().removeAllEvents();

        // 週カレンダーの表示範囲を切り替え
        this.calendar.getApi().gotoDate(formatted_date);
        this.calendar.getApi().el.querySelector<HTMLButtonElement>('.fc-today-button').disabled = false;  // 今日ボタンの非活性を無効化

        // サーバー通信、表示データ更新
        if (updateData === true) {
          console.log(updateData);
          this.communicationsCommand(true);
        }

        // 表示データ更新
        else this.addCustomEventSource();

        // 今日ボタンの非活性処理
        this.setTodayButtonDisabled();
      }
    }
    else {
      console.error(`解釈不能な日付: ${date}`);
    }
  }

  /**
   * 空き情報を取得するスケジュール期間を取得する。
   * スケジュール期間：選択日（targetDate）から7日間
   * targetDateの設定がない場合は、現在日から7日間を取得する。
   *
   * @private
   * @param {string} [targetDate]
   * @return {*}  {({ start: Date | moment.Moment, end: Date | moment.Moment })}
   * @memberof ExpDetailType1Component
   */
  private getScheduleRange(targetDate?: string): { start: Date | moment.Moment, end: Date | moment.Moment } {

    let start: Date | moment.Moment;
    let end: Date | moment.Moment;

    if (!targetDate) targetDate = moment().format(moment.HTML5_FMT.DATE);

    start = moment(targetDate).startOf('day');
    end = moment(targetDate).add(6, 'days').endOf('day');
    console.log("指定した日から一週間を設定：" + moment(start).format("YYYY-MM-DD HH:mm") +" ~ "+ moment(end).format("YYYY-MM-DD HH:mm"));
  
    return { start, end };
  }

  /**
   * 詳細画面に戻った際の処理を行う。
   *
   * @private
   * @param {boolean} [update]
   * @memberof ExpDetailType1Component
   */
  private backToDetail(updateSchedule: boolean, updateService: boolean = false): void {

    // このページを表示する時にカレンダーサイズを整える
    // note: このページが表示していないときにwindow.resizeが起こると、カレンダーサイズが不正となるため
    this.calendar.getApi()?.updateSize();

    // サービス情報更新
    if (updateService) return this.updateService();

    // 空き時間の更新を行うかどうか
    if (updateSchedule) {
      // イベント、ボタン初期化
      this.calendar.getApi().removeAllEvents();
      this.initial();

      setTimeout(() => {
        // 営業時間、空き情報を取得
        this.communicationsCommand(true);
      }, 0);
    }
    // 空き時間の更新を行わない場合でも、カレンダーの初期表示時間を指定
    // else this.calendar.getApi().scrollToTime(this.startTime);
  }

  /**
   * サービス情報を更新
   *
   * @private
   * @memberof ExpDetailComponent
   */
  private updateService(): void {

    this.busy = this.expServ.getService({sg_service_id: this.service.sg_service_id}).subscribe({
      next: res => {
        const service: ExpService = res.body;
        const isLink: boolean = this.params.data.isLink ? true : false;

        this.navigator.element.replacePage(ExpDetailType1Component, {
          animation: 'fade-ios',
          data: {
            service: service,
            updated: true,
            isLink: isLink
          }
        });
      }
    })
  }

  /**
   * 空き時間
   * カレンダーイベント登録
   *
   * @private
   * @memberof ExpDetailType1Component
   */
  private addCustomEventSource(): void {

    // イベントソース
    let events: EventInput[] = [];

    /**
     * イベントソース作成
     *
     * @param {string} status
     * @param {Date} start
     * @param {Date} end
     */
    const createEvents = (status: string, start: Date, end: Date) => {

      const bgColor = status === this.available.OK ? "white" : "lightgray";
      events.push({ title: status, start: start, end: end, backgroundColor: bgColor });
    };

    this.targetResource.availables.forEach(r => {

      const start = new Date(r.from);
      let end = new Date(r.from);
      end.setMinutes(end.getMinutes() + 30);

      // 選択している予約総数を取得
      const total_reservnum = this.expBill.numbers.map(n => n.value).reduce((a, b) => a + b);

      // 予約締切を過ぎている場合はNG
      const deadline_prop = this.service.reserve_deadline;
      if (deadline_prop.acpt_onday===true) {
        /** 締切時刻(これより後ろの時刻開始のサービスしか予約できない) */
        const deadline = moment().add(deadline_prop.acpt_time, 'm');
        if (deadline.isSameOrAfter(start)) {
          createEvents(this.available.NG, start, end);
          return;
        };
      }
      else if (deadline_prop.acpt_onday===false) {
        const deadline_time = deadline_prop.to.time.match(/(\d+):(\d+)/);
        /** 本日期限 */
        const today_deadline = moment().hour(Number(deadline_time[1])).minute(Number(deadline_time[2])).startOf('minute');
        /** 締切時刻(これより後ろの時刻開始のサービスしか予約できない) */
        let deadline: moment.Moment;
        if (moment().isBefore(today_deadline)) {
          deadline = moment().add(deadline_prop.to.day, 'd').startOf('D');  // N日後からの予約が出来る  (N=deadline_prop.to.day)
        }
        else {
          deadline = moment().add(deadline_prop.to.day+1, 'd').startOf('D');  // N日後の予約期限も過ぎているため、N+1日後からの予約が出来る
        }

        if (deadline.isSameOrAfter(start)) {
          createEvents(this.available.NG, start, end);
          return;
        }
      }

      // ----------------------------------------------------
      // 空き日程 〇×設定
      //----------------------------------------------------
      const targetHour: ShopCalendar = this.businessHours.find(hour => moment(hour.date).isSame(r.from, 'day'));

      let isBusiness: boolean;

      // 営業時間が設定されていない
      if (targetHour === void 0) isBusiness = false;
      else {// 営業時間内かどうかチェック
        isBusiness = targetHour.opening_hours.some(opening => {
          const from = this.moment(opening.shipping.from, 'HH:mm');
          const to = this.moment(opening.shipping.to, 'HH:mm');

          return this.moment(targetHour.date).set({'hour': from.hour(), 'minute': from.minute()}).isSameOrBefore(moment(r.from).format('YYYY-MM-DD HH:mm'))
          && this.moment(targetHour.date).set({'hour': to.hour(), 'minute': to.minute()}).isSameOrAfter(moment(r.to).format('YYYY-MM-DD HH:mm'));
        });
      }

      /**
       * 〇×設定
       * @param reservable
       */
      const createSchedule = (reservable: boolean) => {

        // 予約可能（営業時間内＆空きあり）
        if (isBusiness === true && reservable === true) {
          events.push({title: "〇", start: start, end: end, backgroundColor: "white"});
        }
        // 予約不可
        else {
          events.push({title: "×", start: start, end: end, backgroundColor: "lightgray"});
        }
      };

      // モビリティ
      if (this.service.service_type === 'MOBILITY') {
        // 空きがあるかチェック(空きが0以上)
        const reservable: boolean = r.available_number === 1 ? true : false;
        createSchedule(reservable);
      }
      // アクティビティ
      else if (this.service.service_type === 'ACTIVITY') {
        // 空きがあるかチェック(選択中利用数が空き数に達しているか)
        const reservable: boolean = r.available_number - total_reservnum >= 0 && r.available_number !== 0 ? true : false;
        createSchedule(reservable);
      }
      else {}
    });

    // イベント登録
    this.calendar.getApi().addEventSource(events);
  }

  /**
   * カレンダーの表示範囲内か判定する。
   *
   * @private
   * @param {Date} start
   * @return {*}  {boolean}
   * @memberof ExpDetailType1Component
   */
  // private isMaxSchedule(start: Date): boolean {

  //   let result: boolean = false;

  //   // 最大予約可能日付
  //   let startMax = new Date(this.datePickerRange.start);
  //   const startMaxSchedule = new Date(startMax.getFullYear(), startMax.getMonth(), startMax.getDate(), 0, 0, 0);

  //   let endMax = new Date(this.datePickerRange.end);
  //   endMax.setDate(this.datePickerRange.end.getDate() + 1);
  //   const endMaxSchedule = new Date(endMax.getFullYear(), endMax.getMonth(), endMax.getDate(), 0, 0, 0);

  //   // 最大予約可能日付の範囲内か
  //   return (startMaxSchedule.getTime() <= start.getTime() && start.getTime() < endMaxSchedule.getTime()) ? true : false;
  // }

  /**
   * 乗り捨て可能なサービスならば、
   * 貸出、返却場所のチェックを行い、異なる場合エラーメッセージを表示
   *
   * @private
   * @memberof ExpDetailType1Component
   */
  private checkIsDropOff(): void {

    if (this.service.service_type === 'MOBILITY') {
      const index = this.template.body.findIndex(b => b.id === "return");

      if (this.service.is_drop_off === false &&
        (this.expBill.return_place.location.lat !== this.expBill.rental_place.location.lat ||
        this.expBill.return_place.location.lng !== this.expBill.rental_place.location.lng)
      ) {
        this.expBill.err_drop_off = true;
        this.template.body[index].valid_msg = "貸出・返却を同じ場所に指定してください";
      }
      else {
        this.expBill.err_drop_off = false;
        this.template.body[index].valid_msg = "";
      }
    }
  }


  /**
   * 予約確定画面への遷移可否を判定し、遷移ボタンをの表示を切り替える。
   *
   * @private
   * @return {*}
   * @memberof ExpDetailType1Component
   */
  private switchOverTransitionBtn(): void {

    const switchBtn = (isDisplay: boolean): void => {
      if (isDisplay === true) this.btn_trans.nativeElement.style.display = "";
      else this.btn_trans.nativeElement.style.display = "none";
    }

    // 開始、終了日時でエラーがある
    if (this.validationState.err_start.check === true || this.validationState.err_end.check === true
    || this.validationState.err_start_end.check === true) return switchBtn(false);

    // カレンダー上の利用開始日時が未選択
    if (this.expBill.start.date == "" || this.expBill.start.time.view == "" ||
    this.expBill.end.date == "" || this.expBill.end.time.view == "") return switchBtn(false);

    // すべての利用数が０以下
    if (this.expBill.numbers.filter(n => n.value === 0).length === this.expBill.numbers.length) return switchBtn(false);

    // いずれかの利用数が未選択
    if (this.expBill.numbers.filter(n => n.value < 0).length !== 0) return switchBtn(false);

    // モビリティ
    if (this.service.service_type === 'MOBILITY') {
      // 乗り捨て不可 & 貸出、返却場所で異なる場所を選択している
      if (this.expBill.err_drop_off === true) return switchBtn(false);
    }
    // アクティビティ
    else if (this.service.service_type === "ACTIVITY") {
      // 最少人数以下
      if (true === this.expBill.err_min_number) return switchBtn(false);
    }
    else ;

    const optionResult: boolean = Object.keys(this.expBill.options).some(id => {
      return this.expBill.options[id].time_err.check === true || this.expBill.options[id].err.check === true;
    })
    if (optionResult === true) return switchBtn(false);

    // 予約確定画面への遷移ボタン表示
    switchBtn(true);
  }

  /**
   * 利用開始日時設定
   * モビリティのみ以下の処理を実施
   * ・モビリティ共通の利用開始日時が設定されている
   *   ⇒営業時間に含まれている: 利用開始日時を含む週の営業時間を取得
   *   ⇒営業時間に含まれていない：現在日時を含む週の営業時間を取得
   *
   * ・設定されていない⇒現在日時を含む営業時間を取得
   *
   * @private
   * @return {*}  {Observable<any>}
   * @memberof ExpDetailType1Component
   */
  private setBusinessHour(retSubject: Subject<boolean>): void {

    // アクティビティサービス or モビリティサービス：モビリティ共通の利用開始日時が設定されていない, 利用開始日時が設定済み
    // ⇒表示中週の営業時間を設定
    if (this.service.service_type === 'ACTIVITY' || this.expServ.mobilityStartDate === "" ||
    (this.expBill.start.date !== "" && this.expBill.start.time.view !== "")) {

      if (this.service.service_type === 'ACTIVITY') console.log("アクティビティのため、モビリティ共通の利用開始日時を考慮しない");
      console.log("表示中週基準で営業時間取得");
      // 営業時間取得（表示中週の営業時間取得）
      retSubject.next(true);
      retSubject.complete();
    }
    // 設定されている
    else {

      this.busy = this.getShopCalendar(true, this.expServ.mobilityStartDate).subscribe({
        next: res => {
          this.businessHours = res.body;
          /**
           * 開始日時が営業時間に含まれているかチェック
           *  含まれていない：現在日時を含む週の営業時間取得
           *  含まれている：開始日時を含む週の営業時間取得
           */

          // 開始日時が営業時間に含まれていない
          if (this.checkIncludeBusinessHour(this.expServ.mobilityStartDate) === false) {
            console.log("設定されている日時が営業時間に含まれていない：" + moment(this.expServ.mobilityStartDate).format("YYYY-MM-DD HH:mm"));
            this.busy =  this.getShopCalendar().subscribe({
              next: res => {
                this.businessHours = res.body;
                retSubject.next(false);
                retSubject.complete();
              },
              error: err => {
                retSubject.error(err);
                retSubject.complete();
              }
            })
          }
          // 開始日時が営業時間に含まれている
          else {
            console.log("設定されている日時が営業時間に含まれている");

            // カレンダー表示週を開始日時を含むように変更
            this.setSelectedDate(this.expServ.mobilityStartDate, false);

            const start: moment.Moment = moment(this.expServ.mobilityStartDate);

            this.expBill.start = {
              date:  start.format('YYYY-MM-DD HH:mm'),
              time: {
                view: start.format('H:mm'),
                hour: start.hour(),
                minute: start.minute()
              }
            };

            this.matStart.select(start.toDate());
            retSubject.next(false);
            retSubject.complete();
          }
        },
        error: err => {
          retSubject.error(err);
          retSubject.complete();
        }
      });
    }
  }

  /**
   * 指定した日時が営業時刻に含まれるかどうか
   *
   * @private
   * @param {(string | moment.Moment)} targetDate
   * @param {ShopCalendar[]} [businessHour] チェック対象の営業時間(指定しない場合、最後に取得した営業時間を使用)
   * @return {*}  {boolean}
   * @memberof ExpDetailType1Component
   */
  private checkIncludeBusinessHour(targetDate: string | moment.Moment, businessHour?: ShopCalendar[]): boolean {

    const target: string = moment.isMoment(targetDate) ? moment(targetDate).format(this.moment.HTML5_FMT.DATETIME_LOCAL) : targetDate;
    const businessHours: ShopCalendar[] = businessHour ?? this.businessHours;
    
    // 期間検索を指定している場合undefinedになるので対応
    if(businessHours === undefined) return false;

    // 指定した日の営業時間を取得
    const targetBusinessHour: ShopCalendar = businessHours.find(hour => moment(hour.date).isSame(target, 'day'));

    if (targetBusinessHour === void 0) return false;

    // 営業時間に含まれるかどうか
    return targetBusinessHour.opening_hours.some(opening => {
      const from = this.moment(opening.shipping.from, 'HH:mm');
      const to = this.moment(opening.shipping.to, 'HH:mm');

      return moment(targetBusinessHour.date).set({'hour': from.hour(), 'minute': from.minute()}).isSameOrBefore(target, 'minute')
      && moment(targetBusinessHour.date).set({'hour': to.hour(), 'minute': to.minute()}).isSameOrAfter(target, 'minute');
    });
  }

  /**
   * 全ての日数選択型オプションをチェック
   *
   * @private
   * @memberof ExpDetailType1Component
   */
  private checkAllTimeOption(): void {
    this.optionGroups.forEach(group => {
      group.options.forEach(option => {
        if (option.time_rule !== 'none') this.checkTimeOption(option);
      });
    });
    this.switchOverTransitionBtn();
  }

  /**
   * 日数選択型オプション、上限利用日数を超えて選択していないかチェック
   *  上限利用日数：サービス利用日数と、オプション諸元の上限利用日数の小さい方
   *
   * @private
   * @param {ExpService["option_groups"][number]["options"][number]} option
   * @return {*}  {void}
   * @memberof ExpDetailType1Component
   */
  private checkTimeOption(option: ExpService["option_groups"][number]["options"][number]): void {

    this.expBill.options[option.sg_option_id].time_err = {
      message: "",
      check: false
    }

    const targetOption: UserSelectOption = this.expBill.options[option.sg_option_id];

    // 時間指定なし(none)の場合、処理を抜ける
    if (option.time_rule === 'none') return;
    // 日数選択型オプション、未選択の場合、処理を抜ける
    if (!targetOption.time_param.selected) return;
    // 開始終了時間が未選択の場合、処理を抜ける
    if (!this.expBill.start.date || !this.expBill.start.time.view
        || !this.expBill.end.date || !this.expBill.end.time.view) return;

    // 日数選択型オプションが表示されていない場合、処理を抜ける
    switch (option.user_option) {
      case 'yesno':
        if (targetOption.yesno_param.value === false) return;
        break;
      case 'select':
        if (targetOption.select_param.value === void 0) return;
        break;
      case 'number':
      case 'comment':
        break;
    }

    /**
     * 日数選択型オプション、選択中時間
     */
    const selected: number = targetOption.time_param.selected;

    if (option.time_rule === 'days') {

      // 開始終了日時をmoment型に設定
      const start_time = moment(this.expBill.start.date).set({'hour': this.expBill.start.time.hour, 'minute': this.expBill.start.time.minute});
      const end_time = moment(this.expBill.end.date).set({'hour': this.expBill.end.time.hour, 'minute': this.expBill.end.time.minute});

      // NOTE: 利用日数は、含まれている日数(例：3日22時～4日2時⇒2日)
      // 開始終了日時、所要時間から利用日数取得
      const start = start_time.startOf('day');
      const end = end_time.subtract(1, 'seconds').add(1,'day').startOf('day');

      console.log(start.toISOString());
      console.log(end.toISOString());

      console.log(end.diff(start, 'days'));
      console.log(this.settingExp.service.max_reserve_hour / 24);

      let dateLimit: number = Math.min(end.diff(start, 'days'), Math.min(Math.floor(this.settingExp.service.max_reserve_hour/24)+1));

      if (option.time_param.to) {
        dateLimit = Math.min(dateLimit, option.time_param.to)
      }

      // テーブル型の場合、料金テーブル(value)最大値と比較し、小さい方を上限
      const targetPriceRule: SgServiceOption["prices"][number] = this.expServ.getTargetOptionPrice(option.prices, start_time);
      if (targetPriceRule.func === 'table') {
        if (option.user_option === 'select' && targetOption.select_param.value === void 0) return;

        if (option.user_option === 'select' && targetOption.select_param.value !== void 0) {
          dateLimit = Math.min(dateLimit, targetPriceRule.select.find(item => item.value === targetOption.select_param.value).rule.table.slice(-1)[0].value);
        }
        else dateLimit = Math.min(dateLimit, targetPriceRule.rule.table.slice(-1)[0].value);
      }

      if (selected > dateLimit) {
          this.expBill.options[option.sg_option_id].time_err = {
            message: "選択可能な最大日数を超えています。選択しなおしてください。",
            check: true
          }
      }
    }
  }

  /**
   * カレンダーの週変更時の設定処理
   *
   * @private
   * @param {string} [weekType] next：翌週 prev：前週
   * @return {*}  {void}
   * @memberof ExpDetailType1Component
   */
  private setCalendarWeekChange(weekType: string){
    
    switch (weekType) {
      case this.calendarWeekType.next:
        this.calendar.getApi().next();
        break;
      case this.calendarWeekType.prev:
        this.calendar.getApi().prev();
        break;
      default:
        break;
    }

    const startDayWeek = this.calendar.getApi().view.activeStart;
    const date = moment(startDayWeek).format(moment.HTML5_FMT.DATE);
    this.setSelectedDate(date);
  }

  /**
   * 今日ボタン非活性判定処理
   * カレンダー表示の先頭（左列）が現在日である場合、今日ボタンを非活性に設定する
   *
   * @private
   * @return {*}  {void}
   * @memberof ExpDetailType1Component
   */ 
  private setTodayButtonDisabled() {
    const today = moment().format(moment.HTML5_FMT.DATE);
    if (moment(this.calendar.getApi().view.activeStart).format(moment.HTML5_FMT.DATE) == today) {
      this.calendar.getApi().el.querySelector<HTMLButtonElement>('.fc-today-button').disabled = true;
    }
  }

  /**
   * カレンダーの見方 ダイアログを表示
   * 注意書きのリンクからダイアログが閉じた場合、利用開始日時へスクロールする
   *
   * @private
   * @return {*}  {void}
   * @memberof ExpDetailType1Component
   */
  private openAvailableDescriptionDialog() {
    const dialogRef = this.dialog.open(ExpDetailAvailableDescriptionDialog,{
      data: {displayText : true}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.isLink) {
        this.startTimeLabel.nativeElement.scrollIntoView({behavior:'smooth', block:'center'});
      }
    })
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * サーバ通信を行う司令部。
   *
   * @private
   * @param {boolean} [isGetBusiness] true：営業時間を取得
   * @memberof ExpDetailType1Component
   */
  private communicationsCommand(isGetBusiness?: boolean): void {

    // 通信開始制御
    const subject = new Subject<boolean>();

    this.busy = subject.subscribe({
      next: ret => {
        if (ret === true) this.businessHourRange = this.getScheduleRange(this.selectedDate);
        let proc: Observable<any>[] = [];
        // 空き取得
        proc.push(this.getAvailable());
        // 営業時間取得（表示中週の営業時間取得）
        if (ret === true) proc.push(this.getShopCalendar(true, this.selectedDate));

        this.busy = forkJoin(proc).subscribe({
          next: result => {
            // ----------------------------------------------------
            // スクロール初期表示時刻、設定
            // ----------------------------------------------------
            if (ret === true) {
              this.businessHours = result[1].body;
              // 時刻チェック
              this.checkStartEnd('start');
            }
            let minStartHours: string = "";
            this.businessHours.forEach((shop) => {

              if (shop?.opening_hours?.length > 0) {

                // 開始日時
                let start: string = "";

                shop.opening_hours.forEach((opening_hour) => {

                  if (start === "") start = opening_hour.shipping.from;
                  // 同じ日に複数営業時間が設定されている場合、
                  // start：開始時刻内で一番早い時刻, end: 終了時刻内で一番遅い時刻
                  else start = start < opening_hour.shipping.from ? start : opening_hour.shipping.from;
                });

                // minStartHours: 週で一番早い営業開始時間
                // minEndHours: 週で一番遅い営業終了時間
                if (minStartHours === "") minStartHours = start;
                else minStartHours = minStartHours < start ? start : minStartHours;
              }
            });

            // 営業時間未設定の場合、8時に設定
            if (minStartHours === "") minStartHours = "8:00";
            setTimeout(() => {
              // スクロール初期表示時刻、設定
              this.calendar.getApi()?.scrollToTime(minStartHours);
            });

            // 空き情報
            const body = result[0].body as ExpResourceAvailable;
            console.log(body);

            this.resourceAvailable = body.resources;

            this.targetResource = { sg_resource_id: null, name: "", availables: []};

            // 請求先(未ログイン時は空)
            if (body.payer) this.expBill.payer_name = body.payer.name;

            // 初期表示処理
            if (isGetBusiness === true) {

              // ----------------------------------------------------
              // リソース初期選択設定処理
              // ----------------------------------------------------
              // 現在時刻
              const now: moment.Moment = moment();

              const resources = body.resources;
              // 空き無し
              // NOTE: サーバーから空きリソースが一つも返ってこなかった(想定外)
              // if (!resources && resources.length === 0)

              console.log("リソース数：" + resources.length);
              // サービスに紐づくリソースが一つの場合
              if (resources.length === 1) this.targetResource = resources[0];
              // サービスに紐づくリソースが複数の場合
              else if (resources.length > 1) {
                // -----------------------
                // リソース初期選択設定処理
                //------------------------
                const resource = resources.find(resource => {
                  // 利用開始日時が未設定
                  if (this.expBill.start.date === "" || this.expBill.start.time.view === "") {
                    // 当日 & 現在時刻より後 & 空きがあるリソース取得
                    const result: boolean = resource.availables.some(available => {
                      return now.isSame(available.from, 'day') && now.isSameOrBefore(available.from, 'minute') && available.available_number > 0;
                    });

                    return result;
                  }
                  // 利用開始日時に空きがあるリソースを取得
                  else {
                    const start = moment(this.expBill.start.date).set({hour: this.expBill.start.time.hour, minute: this.expBill.start.time.minute});
                    const result = resource.availables.find(available => start.isSame(available.from, 'minute'));

                    if (result !== void 0 && result.available_number > 0) return true;
                  }
                });

                // 利用可能なリソースが存在しない場合、一つ目のリソースを初期選択
                if (resource === void 0) this.targetResource = resources[0];
                else this.targetResource = resource;

                // --------------------------------------------------------------------------------
                // 確認用
                // --------------------------------------------------------------------------------
                // if (resource === void 0) {
                //   // console.log(resources[0]);
                //   console.log("利用可能なリソースが存在しない");
                // }
                // else {
                //   // console.log(resource);
                //   console.log("利用可能なリソースが存在する：" + resource.sg_resource_id);
                // }

                // this.testtest = [];

                // this.service.service_providers.forEach(test => {
                //   this.testtest.push(test.resource.sg_resource_id);
                // });
                // --------------------------------------------------------------------------------
              }
              console.log("週範囲：" + moment(this.businessHourRange.start).format("YYYY-MM-DD HH:mm") + " ~ " + moment(this.businessHourRange.end).format("YYYY-MM-DD HH:mm"));
            }

            // カレンダーイベント登録
            this.addCustomEventSource();

            console.log(this.businessHours);
          },
          error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
        });
      },
      error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
    });

    if (isGetBusiness === true) this.setBusinessHour(subject);
    else {
      subject.next(false);
      subject.complete();
    }
  }

  /**
   * 店舗の営業時間を取得する。
   *
   * @private
   * @param {boolean} [update=true]
   * 取得した営業時間を保存するかどうか
   * @param {string} [targetDate=""]
   *  日時(targetDate)を指定：targetDateを含む週の営業時間を取得。
   *  日時を指定しない：表示中カレンダーの週の営業時間を取得。
   * @return {*}  {Observable<HttpResponse<ShopCalendar[]>>}
   * @memberof ExpDetailType1Component
   */
  private getShopCalendar(update: boolean = true, targetDate: string = ""): Observable<HttpResponse<ShopCalendar[]>> {

    console.log("getShopCalendar");

    // スケジュール期間を取得
    const range = this.getScheduleRange(targetDate);

    if (update === true) this.businessHourRange = range;

    // サーバ通信
    return this.expServ.getShopCalendar(this.service.shop.shop_id, range.start.toISOString(), range.end.toISOString());
  }

  /**
   * サービスの空き情報を取得する。
   *
   * @private
   * @return {*}  {Observable<HttpResponse<ExpResourceAvailable>>}
   * @memberof ExpDetailType1Component
   */
  private getAvailable(): Observable<HttpResponse<ExpResourceAvailable>> {

    // スケジュール期間を取得
    console.log("getAvailable");

    const param: parameter.ExpAvailable = {
      sg_service_id : this.service.sg_service_id,
      date_from: this.businessHourRange.start.toISOString(),
      date_to: this.businessHourRange.end.toISOString(),
      util_time: 30
    };
    const method: Observable<HttpResponse<ExpResourceAvailable>> = this.expServ.getResourceAvailable(param);

    return method;
  }
}


export interface ExpBill {

  /**
   * 貸出場所
   *
   * @type {ExpOptionPlace}
   * @memberof ExpReservationConfirm
   */
  rental_place?: ExpPlace;

  /**
   * 返却場所
   *
   * @type {ExpOptionPlace}
   * @memberof ExpReservationConfirm
   */
  return_place?: ExpPlace;

  /**
   * 開催場所
   *
   * @type {ExpOptionPlace}
   * @memberof ExpReservationConfirm
   */
  place?: ExpPlace;

  /**
   * 乗り捨て不可エラー
   *
   * @type {boolean}
   * @memberof ExpBill
   */
  err_drop_off: boolean;

  /**
   * 最少人数エラー
   *
   * @type {boolean}
   * @memberof ExpBill
   */
  err_min_number: boolean;

  /**
   * 料金
   *
   * @type {number}
   * @memberof ExpBill
   */
  price: {
    name: string;
    value: number;
  }[];

  /**
   * 利用数
   *
   * @type {number}
   * @memberof ExpReservationConfirm
   */
  // number?: number;

  /**
   * 利用数単位
   *
   * @type {string}
   * @memberof ExpReservationConfirm
   */
  unit?: string;

  /**
   * 利用数
   *
   * @memberof ExpBill
   */
  numbers? : {

    /**
     * 料金タイプ
     *
     * @type {number}
     */
    type: number;

    /**
     * 利用数
     *
     * @type {number}
     */
    value: number;
  }[];

  /**
   * 利用時間(分)
   *
   * @type {number}
   * @memberof ExpReservationConfirm
   */
  // util_time?: number;

  /**
   * 利用開始日時
   *
   * @type {string}
   * @memberof ExpReservationConfirm
   */
  // start_time?: string;

  start?: {
    date: string;
    time: {
      view: string;
      hour: number;
      minute: number;
    };
  };

  end?: {
    date: string;
    time: {
      view: string;
      hour: number;
      minute: number;
    };
  }

  /**
   * 利用終了日時
   *
   * @type {string}
   * @memberof ExpReservationConfirm
   */
  // end_time?: string;

  /**
   * 利用可能時間帯
   *
   * @memberof ExpBill
   */
  time_range?: {

    /**
     * 利用開始時間
     *
     * @type {string}
     */
    from: string;

    /**
     * 利用終了時間
     *
     * @type {string}
     */
    to: string;
  }

  /**
   * 請求先
   *
   * @type {string}
   * @memberof ExpReservationConfirm
   */
  payer_name: string;

  /**
   * オプション
   *
   * @type {{[option_id: string]: UserSelectOption}}
   * @memberof ExpBill
   */
  options?: {[option_id: string]: UserSelectOption};
}

/**
 *
 *
 * @interface UserSelectOption
 */
// interface UserSelectOption {
//   sg_option_price_id?: number;
//   comment?: string;
//   err?: {
//     check: boolean;
//     message: string;
//   };
//   yesno_param?: SgServiceOption["yesno_param"]["items"][number];
//   select_param?: SgServiceOption["select_param"]["items"][number];
//   number_param?: SgServiceOption["number_param"];
//   time_param?: SgServiceOption["time_param"];
// }

export interface ValidationState {
  /**
   * 乗り捨て不可エラー
   *
   * @type {boolean}
   * @memberof ExpBill
   */
  err_drop_off?: {
    check: boolean;
    message?: string;
  };

  /**
   * 最少人数エラー
   *
   * @type {boolean}
   * @memberof ExpBill
   */
  err_min_number?: boolean;

  err_start?: {
    check: boolean;
    message?: string;
  };

  err_end?: {
    check: boolean;
    message?: string;
  };

  err_start_end?: {
    check: boolean;
    message?: string;
  };
}

@Component({
  selector: 'exp-detail-type1-available-dialog',
  templateUrl: './exp-detail-type1-available-dialog.component.html'
})
export class ExpDetailAvailableDescriptionDialog {

  constructor(
    public dialogRef: MatDialogRef<ExpDetailAvailableDescriptionDialog>,
    @Inject (MAT_DIALOG_DATA) public data: any
  ) {}

  /**
   *
   *
   * @memberof ExpDetailAvailableDescriptionDialog
   */
  // onNoClick(): void {
  //   this.dialogRef.close();
  // }
  
  onAvailableDialogCautionLinkClick(): void {
    this.dialogRef.close({
      isLink : true
    });
  }
}
