<ons-toolbar class="toolbar">
  <div class="center">サインイン</div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="busy"></div>

  <!-- <p class="content--text">
    SNS アカウントでサインイン<br />
    （ログイン／新規登録）
  </p>

  <ons-button id="signin_001" modifier="large--cta noFixed sns cta--line" (click)="signinSNS('line')">
    <img class="button-icon-sns" src="../../assets/icon/sns/line/btn_base.png"/>
    <div class="content--text-over">LINE</div>
  </ons-button>

  <ons-button modifier="large--cta noFixed sns cta--fb" (click)="signinSNS('facebook')">
    Facebook
  </ons-button>

  <ons-button modifier="large--cta noFixed sns cta--twtr" (click)="signinSNS('twitter')">
    Twitter
  </ons-button>

  <ons-button modifier="large--cta noFixed sns cta--yj" (click)="signinSNS('yj')">
    Yahoo!
  </ons-button>

  <ons-button id="signin_002" modifier="large--cta noFixed sns cta--ggl text-color" (click)="signinSNS('google')">
    <img class="button-icon-sns" src="../../assets/icon/sns/google/btn_google_light_normal_ios@3x.png"/>
    <div class="content--text-over">Google</div>
  </ons-button>

  <div class="notes-wrap">
    <div class="button-wrap">
      <ons-button id="signin_003" modifier="quiet" class="password" (click)="cannotSignin()"><span>サインインできない場合</span></ons-button>
    </div>
  </div> -->

  <div class="mail-wrap">
    <p class="content--text">
      メールアドレスでログイン
    </p>
    <ons-input id="signin_004" modifier="underbar" placeholder="メールアドレス" [(value)]="username" type="email" autocomplete="username"></ons-input>
    <ons-input id="signin_005" modifier="underbar" placeholder="パスワード" [(value)]="password" type="password"></ons-input>
    <div class="button-wrap">
      <ons-button id="signin_006" modifier="quiet" class="password" (click)="forgetPassword()">パスワードを忘れた場合</ons-button>
      <ons-button id="signin_007" modifier="large--cta noFixed sns" class="login" (click)="signinLocal()"><img class="button-icon"
          [src]="ASSETS_MAIL" />ログイン</ons-button>
    </div>
  </div>
  <div class="content--text-wrap">
    <p class="content--text">メールアドレスで新規登録する方は</p>
    <ons-button id="signin_008" modifier="quiet" (click)="signup()"><span class="button-text">こちら</span></ons-button>
  </div>
  <hr>
</div>
