<ons-toolbar>
  <div class="left"><ons-back-button #onsBackButton></ons-back-button></div>
  <div class="center">観光サービス詳細</div>
</ons-toolbar>

<div [ngBusy]="busy"></div>
<div class="content">

  <!-- header -->
  <parts-ons-list [listInfo]="template.header"></parts-ons-list>

  <parts-carousel [images]="service.images"></parts-carousel>

  <!-- 説明 -->
  <div class="list-item__caption">説明</div>
  <ons-list>
    <ons-list-item class="list-item__text" modifier="longdivider">
      <ng-container *ngIf="dispDescription; else noItem">
        <div class="common--text" [innerHtml]="dispDescription"></div>
      </ng-container>
    </ons-list-item>
  </ons-list>

  <!-- 利用開始日時、終了日時 -->
  <ons-list>
    <div #startTimeLabel class="list-item__caption">利用開始日時</div>
    <ons-list-item>
      <div class="exp-time-wrap">
        <div (click)="startTime.open()" style="margin-right: 10px;">
          <span *ngIf="expBill.start.date !== ''; else noDate">{{moment(expBill.start.date).format('YYYY年M月D日')}}</span>
          <mat-datepicker #startTime hidden></mat-datepicker>
          <button class="mat-icon__size" mat-icon-button><span class="material-icons calendar-icon">calendar_month</span></button>
          <input matInput [matDatepicker]="startTime" [min]="datePickerRange.start" [max]="datePickerRange.end" (dateChange)="onChangeDate($event, 'start')" hidden>
        </div>
  
        <div  [matMenuTriggerFor]="startTimeMenu" style="display: flex; align-items: center;">
          <span *ngIf="expBill.start.time.view !== ''; else noTime">{{expBill.start.time.view}}</span>
          <p>
            <button class="mat-icon__size" mat-icon-button>
              <span class="material-icons">
                arrow_drop_down
              </span>
            </button>
            <mat-menu #startTimeMenu="matMenu" xPosition="before">
              <ng-container *ngFor="let item of hourMinuteList; index as i">
                <button class="mat-menu-item__height" mat-menu-item (click)="onChangeTime(item, 'start')"> <!-- [disabled]="expBill.start.date==''" -->
                  {{item.view}}
                </button>
              </ng-container>
            </mat-menu>
          </p>
        </div>
      </div>
      <ng-container *ngIf="validationState && validationState.err_start && validationState.err_start.check === true">
        <div class="valid-msg" >
          <span>{{validationState.err_start.message}}</span>
        </div>
      </ng-container>
    </ons-list-item>
  </ons-list>

  <ons-list>
    <div class="list-item__caption">利用終了日時</div>
    <ons-list-item>
      <div class="exp-time-wrap">
        <div  (click)="endTime.open()" style="margin-right: 10px;">
          <span *ngIf="expBill.end.date !== ''; else noDate">{{moment(expBill.end.date).format('YYYY年M月D日')}}</span>
          <!-- datepicker -->
          <mat-datepicker #endTime [startAt]="expBill.start.date" hidden></mat-datepicker>
          <button class="mat-icon__size" mat-icon-button><span class="material-icons calendar-icon">calendar_month</span></button>
          <input matInput [matDatepicker]="endTime" [min]="datePickerRange.start" [max]="datePickerRange.endTime" (dateChange)="onChangeDate($event, 'end')" hidden>
        </div>
        <div  [matMenuTriggerFor]="endTimeMenu" style="display: flex; align-items: center;">
          <span *ngIf="expBill.end.time.view !== ''; else noTime">{{expBill.end.time.view}}</span>
          <p>
            <button class="mat-icon__size" mat-icon-button>
              <span class="material-icons">
                arrow_drop_down
              </span>
            </button>
            <mat-menu #endTimeMenu="matMenu" xPosition="before">
              <ng-container *ngFor="let item of hourMinuteList; index as i">
                <button class="mat-menu-item__height" mat-menu-item (click)="onChangeTime(item, 'end')">
                  {{item.view}}
                </button>
              </ng-container>
            </mat-menu>
          </p>
        </div>
      </div>
      <ng-container *ngIf="validationState && validationState.err_end && validationState.err_end.check === true">
        <div class="valid-msg" >
          <span>{{validationState.err_end.message}}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="validationState && validationState.err_start_end && validationState.err_start_end.check === true">
        <div class="valid-msg" >
          <span>{{validationState.err_start_end.message}}</span>
        </div>
      </ng-container>
      
    </ons-list-item>
  </ons-list>

  <!-- オプション -->
  <ng-container  *ngIf="expBill.options">
    <ng-container *ngFor="let group of optionGroups">

      <!-- ヘッダー -->
      <div *ngIf="group.options" class="list-item__caption">
        <span *ngIf="group.options.length === 1" class="exp-option-wrap__item">
          <span>{{group.options[0].name}}</span>
          &nbsp;<button #optionDialog class="info_button" style="border: solid 1px !important;" (click)="onClickOptionInfo(group.options[0])"><img [src]="ASSETS.INFORMATION"></button>
        </span>
        <span *ngIf="group.options.length >= 2">{{group.name}}</span>
      </div>
  
      <ng-container *ngFor="let option of group.options">
        <ons-list>
          <ng-container *ngIf="option.user_option !== 'comment'">
            <ons-list-item *ngIf="group.options.length == 1" modifier="nodivider" [matMenuTriggerFor]="menu1">
              <span class="exp-option-wrap">
                <div *ngIf="expBill.options[option.sg_option_id]">
                  <span style="overflow-wrap: anywhere;" *ngIf="option.user_option === 'yesno'">{{expBill.options[option.sg_option_id].yesno_param.name}}</span>
                  <span style="overflow-wrap: anywhere;" *ngIf="option.user_option === 'select'">{{expBill.options[option.sg_option_id].select_param.name}}</span>
                  <span style="overflow-wrap: anywhere;" *ngIf="option.user_option === 'number'">
                    <span>{{expBill.options[option.sg_option_id].number_param.selected}}</span>
                    <span style="overflow-wrap: anywhere;">{{option.unit}}</span>
                  </span>
                </div>
                <div>
                  <button class="link_select-box__drop-down-menu" mat-icon-button>
                    <span class="material-icons">
                      arrow_drop_down
                    </span>
                  </button>
                  <mat-menu #menu1="matMenu" xPosition="before">
                    <ng-container *ngIf="option.user_option === 'yesno'">
                      <ng-container *ngFor="let item of option.yesno_param.items">
                        <button class="mat-menu-item__height" mat-menu-item (click)="onChangeOptionYesno(option, item)">{{item.name}}</button>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="option.user_option === 'select'">
                      <ng-container *ngFor="let item of option.select_param.items">
                        <button class="mat-menu-item__height" mat-menu-item (click)="onChangeOptionSelect(option, item)">{{item.name}}</button>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="option.user_option === 'number' && expBill.options[option.sg_option_id].number_param.select">
                      <ng-container *ngFor="let item of expBill.options[option.sg_option_id].number_param.select">
                        <button class="mat-menu-item__height" mat-menu-item (click)="onChangeOptionNumber(option, item)">{{item}}{{option.unit}}</button>
                      </ng-container>
                    </ng-container>
                  </mat-menu>
                </div>
              </span>
            </ons-list-item>
            <ons-list-item *ngIf="group.options.length >= 2" modifier="nodivider" [matMenuTriggerFor]="menu2">
              <p class="exp-option-wrap">
                <span class="exp-option-wrap__item">
                  <span style="overflow-wrap: anywhere;">{{ option.name }}</span>
                  &nbsp;<button #optionDialog class="info_button" style="border: solid 1px !important;" (click)="onClickOptionInfo(option)"><img [src]="ASSETS.INFORMATION"></button>
                </span>
                <span class="link_select-box">
                  <div *ngIf="expBill.options[option.sg_option_id]">
                    <span style="overflow-wrap: anywhere;" *ngIf="option.user_option === 'yesno'">{{expBill.options[option.sg_option_id].yesno_param.name}}</span>
                    <span style="overflow-wrap: anywhere;" *ngIf="option.user_option === 'select'">{{expBill.options[option.sg_option_id].select_param.name}}</span>
                    <span style="overflow-wrap: anywhere;" *ngIf="option.user_option === 'number'">
                      <span>{{expBill.options[option.sg_option_id].number_param.selected}}</span>
                      <span style="overflow-wrap: anywhere;">{{option.unit}}</span>
                    </span>
                  </div>
                  <div>
                    <button class="link_select-box__drop-down-menu" mat-icon-button>
                      <span class="material-icons">
                        arrow_drop_down
                      </span>
                    </button>
                    <mat-menu #menu2="matMenu" xPosition="before">
                      <ng-container *ngIf="option.user_option === 'yesno'">
                        <ng-container *ngFor="let item of option.yesno_param.items">
                          <button class="mat-menu-item__height" mat-menu-item (click)="onChangeOptionYesno(option, item)">{{item.name}}</button>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="option.user_option === 'select'">
                        <ng-container *ngFor="let item of option.select_param.items">
                          <button class="mat-menu-item__height" mat-menu-item (click)="onChangeOptionSelect(option, item)">{{item.name}}</button>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="option.user_option === 'number' && expBill.options[option.sg_option_id].number_param.select">
                        <ng-container *ngFor="let item of expBill.options[option.sg_option_id].number_param.select">
                          <button class="mat-menu-item__height" mat-menu-item (click)="onChangeOptionNumber(option, item)">{{item}}{{option.unit}}</button>
                        </ng-container>
                      </ng-container>
                    </mat-menu>
                  </div>
                </span>
              </p>
            </ons-list-item>      
          </ng-container>

          <ons-list-item modifier="nodivider" *ngIf="option.user_option === 'comment'">
            <ons-input [(ngModel)]="expBill[option.sg_option_id].comment"></ons-input>
          </ons-list-item>
  
          <ng-container *ngIf="option.user_option !== 'yesno' || !(option.user_option === 'yesno' && expBill.options[option.sg_option_id].yesno_param.value === false)">
            <ons-list-item modifier="nodivider" *ngIf="(option.time_rule === 'days' || option.time_rule === 'mins') && expBill.options[option.sg_option_id].time_param" [matMenuTriggerFor]="menu">
              <p class="exp-option-wrap">
                <span class="exp-option-wrap__item" style="padding-left: 15px;">
                  <span *ngIf="option.time_rule === 'days'" style="overflow-wrap: anywhere;">利用日数</span>
                  <span *ngIf="option.time_rule === 'mins'" style="overflow-wrap: anywhere;">利用時間</span>
                </span>
    
                <span class="exp-option-wrap__item">
                  <div>
                    <span>{{expBill.options[option.sg_option_id].time_param.selected}}</span>
                    <span style="overflow-wrap: anywhere;" *ngIf="option.time_rule === 'days'">日</span>
                    <span style="overflow-wrap: anywhere;" *ngIf="option.time_rule === 'mins'">分</span>
                  </div>
                  <div>
                    <button class="link_select-box__drop-down-menu" mat-icon-button>
                      <span class="material-icons">
                        arrow_drop_down
                      </span>
                    </button>
                    <mat-menu #menu="matMenu" xPosition="before">
                      <ng-container *ngFor="let item of expBill.options[option.sg_option_id].time_param.select">
                        <button class="mat-menu-item__height" mat-menu-item (click)="onChangeOptionTime(option, item)">
                          {{item}}
                          <ng-container *ngIf="option.time_rule === 'days'">日</ng-container>
                          <ng-container *ngIf="option.time_rule === 'mins'">分</ng-container>
                        </button>
                      </ng-container>
                    </mat-menu>
                  </div>
                </span>
              </p>
              <div class="valid-msg" *ngIf="expBill.options[option.sg_option_id].time_err">
                <div *ngIf="expBill.options[option.sg_option_id].time_err.check ===true">
                  <span>{{expBill.options[option.sg_option_id].time_err.message}}</span>
                </div>
              </div>
            </ons-list-item>

          </ng-container>
        </ons-list>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- body -->
  <ng-container *ngFor="let bo of template.body; index as i">
    <parts-ons-list [listInfo]="bo" (partsSelectEvent)="selectedEvent($event)"></parts-ons-list>
  </ng-container>

  <!-- datepicker -->
  <mat-datepicker #matDatePicker hidden></mat-datepicker>
  <input matInput [matDatepicker]="matDatePicker" (dateChange)="onChangeTargetDate($event)" [value]="selectedDate" [min]="datePickerRange.start" [max]="datePickerRange.end" hidden>

  <div class="list-item__caption available-title">
    <span>空き日程</span>
    &nbsp;<button class="info_button" style="border: solid 1px !important;" (click)="onClickAvailableInfo()"><img [src]="ASSETS.INFORMATION"></button>
  </div>
  <ons-list>
    <ons-list-item modifier="longdivider" [matMenuTriggerFor]="resourceList">
      <p class="exp-option-wrap">
        <span>{{targetResource.name}}</span>
        <button class="link_select-box__drop-down-menu mat-icon__size" mat-icon-button>
          <span class="material-icons">
            arrow_drop_down
          </span>
        </button>
        <mat-menu #resourceList="matMenu" xPosition="before">
          <ng-container *ngFor="let item of resourceAvailable; index as i">
            <button class="mat-menu-item__height" mat-menu-item (click)="onChangeResource(item)">
              {{item.name}}
            </button>
          </ng-container>
        </mat-menu>
      </p>
    </ons-list-item>

    <div class="valid-msg" *ngIf="resourceAvailable.length !== 0">
      <div *ngIf="service.service_type === 'MOBILITY'" class="valid-msg__list-item">
        <span>※上記モビリティへの予約を確約するものではありません。</span>
      </div>
      <div *ngIf="service.service_type === 'ACTIVITY'" class="valid-msg__list-item">
        <span>※上記選択肢への予約を確約するものではありません。</span>
      </div>
    </div>
  </ons-list>

  <!-- calendar -->
  <div class="calendar-wrap" id="available-type1">
    <full-calendar id="week-calendar" #calendar [options]="calendarOptions"></full-calendar>
  </div>

  <!-- footer -->
  <parts-ons-list [listInfo]="template.footer"></parts-ons-list>
  
  <div #btn_trans class="button-wrap button-sticky">
    <ons-button modifier="large--cta" (click)="onTransition()">
      予約内容確認へ
    </ons-button>
  </div>
</div>

<ng-template #noDate>年/月/日</ng-template>

<ng-template #noTime>-- : --</ng-template>

<ng-template #onlyOne><span>&nbsp;a</span></ng-template>

<ng-template #noItem><mat-icon >remove</mat-icon></ng-template>