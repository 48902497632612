//=============================================================================================
// インポート
//=============================================================================================
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as qs from 'qs';
import * as CONST from '../constants/constant';

// interface
import { common } from '../interfaces/common';
import { parameter } from '../interfaces/parameter';
import { request } from '../interfaces/request';
import { Shop, ShoppingMenu, DeliveryPlan, Order, PossessionsOrderItem, PossessionsDeliveryTicket } from '../interfaces/response';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * ServiceConfig
 *
 * @export
 * @class ShoppingWebApiServiceConfig
 */
export class ShoppingWebApiServiceConfig {
  /**
   * Web API の基底 URL。
   *
   * @type {string}
   * @memberof ShoppingWebApiServiceConfig
   */
   baseUrl: string;

   /**
    * HTTP リクエストと一緒に送信されるオプション。
    *
    * @type {({
    *     headers?: HttpHeaders | {
    *       [header: string]: string | string[];
    *     };
    *   })}
    * @memberof ShoppingWebApiServiceConfig
    */
   httpOptions?: {
     /**
      * HTTP ヘッダー。
      *
      * @type {(HttpHeaders | {
      *       [header: string]: string | string[];
      *     })}
      */
     headers?: HttpHeaders | 
     {
       [header: string]: string | string[];
     };
   };
}

/**
 * 宅配サービス
 *
 * @export
 * @class ShoppingWebApiService
 */
@Injectable({
  providedIn: 'root'
})
export class ShoppingWebApiService {

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ShoppingWebApiService.
   * @param {HttpClient} http
   * @param {ShoppingWebApiServiceConfig} config
   * @memberof ShoppingWebApiService
   */
  constructor(
    private http: HttpClient, 
    private config: ShoppingWebApiServiceConfig
  ) { }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 配達プランの一覧を作成する。
   *
   * @param {DeliveryPlan[]} plan
   * @return {*}  {common.DeliveryPlanList[]}
   * @memberof ShoppingWebApiService
   */
  public getSoppingPlan(deliveryPlan: DeliveryPlan[]): common.DeliveryPlan[] {

    // 時間帯成形（HH:MMを返却）
    const timezone = (date: Date): string => {
      return date.getHours() + ":" + ('00' + date.getMinutes()).slice(-2);
    }

    try {
      
      let planList: common.DeliveryPlan[] = [];
      if (deliveryPlan.length === 0) return planList;

      // 日付毎の時間帯リストを作成
      deliveryPlan.forEach(p => {

        // 配達プラン初期化
        let plan: common.DeliveryPlan = {
          plan_id: "", 
          d: undefined, 
          user: undefined, 
          payer: undefined, 
          cancelable_time: undefined, 
          total: {
            price: 0, 
            refundPrice: 0, 
            payer_name: ""
          }, 
          shop: [], 
          sections: []
        };
        
        plan.plan_id = p.delivery_plan_id;
        plan.cancelable_time = p.cancelable_time;

        // this.createProc(plan, p);

        // 配達プラン生成
        plan.plan_id = p.delivery_plan_id ?? "";
        plan.d = p.packages[0].sections[0].d.place;
        plan.user = p.user;
        plan.payer = p.payer;
        plan.cancelable_time = p.cancelable_time;

        p.packages.forEach((pack, index: number) => {

          plan.shop.push({ 
            id: pack.shop.shop_id, 
            name: pack.shop.name, 
            o: undefined, 
            chages: 0, 
            day: "", 
            time_zone: { from: undefined, to: undefined, from_str: "", to_str: undefined }
           })
          
          pack.sections.forEach(sec => {

            plan.shop[index].o = sec.o.place;
            plan.total.price += sec.amount;
            plan.sections.push(sec);
          });

          plan.shop[index].time_zone.from = p.packages[index].sections[p.packages[index].sections.length - 1].d.schd_time.period.from;
          plan.shop[index].time_zone.to = p.packages[index].sections[p.packages[index].sections.length - 1].d.schd_time.period.to;
          plan.shop[index].time_zone.from_str = timezone(new Date(plan.shop[index].time_zone.from));
          plan.shop[index].time_zone.to_str = timezone(new Date(plan.shop[index].time_zone.to));

          const to_date = new Date(plan.shop[index].time_zone.to);
          const day = to_date.getMonth() + 1 + "月" + to_date.getDate() + "日";
          plan.shop[index].day = day;
        });

        // リストに追加
        planList.push(plan);
      });

      return planList;
    }
    catch (err) 
    {
      return [];
    }
  }

  
  public getSoppingOrder(order: Order[]): common.UserOrder[] {

    // 時間帯成形（HH:MMを返却）
    const timezone = (date: Date): string => {
      return date.getHours() + ":" + ('00' + date.getMinutes()).slice(-2);
    }

    try {
      
      let orderList: common.UserOrder[] = [];
      if (order.length === 0) return orderList;

      // 日付毎の時間帯リストを作成
      order.forEach(p => {

        // 配達プラン初期化
        let ord: common.UserOrder = {
          plan_id: "", 
          order_id: "", 
          d: undefined, 
          user: undefined, 
          payer: undefined, 
          cancelable_time: undefined, 
          total: {
            price: 0, 
            refundPrice: 0, 
            payer_name: ""
          }, 
          shop: [], 
          sections: []
        };
        
        ord.order_id = p.order_id;
        ord.cancelable_time = p.cancelable_time;


        // 配達プラン生成
        ord.d = p.packages[0].sections[0].d.place;
        ord.user = p.user;
        ord.payer = p.payer;
        
        p.shops.forEach(s => {
          ord.shop.push({ 
            id: s.shop_id, 
            name: s.name, 
            o: undefined, 
            chages: 0, 
            day: "", 
            time_zone: { from: undefined, to: undefined, from_str: "", to_str: undefined }
          });
        });

        p.packages.forEach((pack, index: number) => {
          
          pack.sections.forEach(sec => {

            ord.shop[index].o = sec.o.place;
            ord.total.price += sec.amount;
            ord.sections.push(sec);
          });

          ord.shop[index].time_zone.from = p.packages[index].sections[p.packages[index].sections.length - 1].d.schd_time.period.from;
          ord.shop[index].time_zone.to = p.packages[index].sections[p.packages[index].sections.length - 1].d.schd_time.period.to;
          ord.shop[index].time_zone.from_str = timezone(new Date(ord.shop[index].time_zone.from));
          ord.shop[index].time_zone.to_str = timezone(new Date(ord.shop[index].time_zone.to));

          const to_date = new Date(ord.shop[index].time_zone.to);
          const day = to_date.getMonth() + 1 + "月" + to_date.getDate() + "日";
          ord.shop[index].day = day;
        });


        // リストに追加
        orderList.push(ord);
      });
      
      return orderList;
    }
    catch (err) 
    {
      return [];
    }
  }

  private createProc(date: common.UserOrder, p: Order): void {
    
    // 時間帯成形（HH:MMを返却）
    const timezone = (date: Date): string => {
      return date.getHours() + ":" + ('00' + date.getMinutes()).slice(-2);
    }

    // 配達プラン生成
    date.d = p.packages[0].sections[0].d.place;
    date.user = p.user;
    date.payer = p.payer;

    p.packages.forEach((pack, index: number) => {

      // if (date.shop !== undefined) {
        date.shop.push({ 
          id: pack.shop.shop_id, 
          name: pack.shop.name, 
          o: undefined, 
          chages: 0, 
          day: "", 
          time_zone: { from: undefined, to: undefined, from_str: "", to_str: undefined }
        });
      // }
      
      pack.sections.forEach(sec => {

        date.shop[index].o = sec.o.place;
        date.total.price += sec.amount;
        date.sections.push(sec);
      });

      date.shop[index].time_zone.from = p.packages[index].sections[p.packages[index].sections.length - 1].d.schd_time.period.from;
      date.shop[index].time_zone.to = p.packages[index].sections[p.packages[index].sections.length - 1].d.schd_time.period.to;
      date.shop[index].time_zone.from_str = timezone(new Date(date.shop[index].time_zone.from));
      date.shop[index].time_zone.to_str = timezone(new Date(date.shop[index].time_zone.to));

      const to_date = new Date(date.shop[index].time_zone.to);
      const day = to_date.getMonth() + 1 + "月" + to_date.getDate() + "日";
      date.shop[index].day = day;
    });
  }

  /**
   * 配送料の合計金額を取得する。
   *
   * @param {Order} order
   * @return {*}  {number}
   * @memberof ShoppingWebApiService
   */
  public getOrderCharge(order: Order): number {

    let amount: number = 0;
    order.packages.forEach(p => p.sections.forEach(s => amount += s.ticket.amount));

    return amount;
  }

  /**
   * 注文キャンセルが可能かどうか
   *
   * @param {Order} order
   * @param {boolean} time_check キャンセル期限をチェックするか
   * @return {*}  {boolean}
   * @memberof ShoppingWebApiService
   */
  public isOrderCancel(order: Order, time_check: boolean): boolean {

    // 店舗の商品準備完了まではユーザキャンセル可能
      if (order.status === 'INIT' || order.status === 'READY') {

      // キャンセル期限もチェック
      if (time_check) {
        if (new Date().getTime() < new Date(order.cancelable_time).getTime()) return true;
        else ;
      }
      else return true;
    }

    return false;
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /*
    店舗
  */

  /**
   * 店舗一覧を取得する。
   *
   * @param {string} freeword
   * @param {boolean} [have_menu=true]
   * @return {*}  {Observable<HttpResponse<Shop[]>>}
   * @memberof ShoppingWebApiService
   */
  public getShopList(freeword: string, have_menu: boolean = true): Observable<HttpResponse<Shop[]>> {

    return this.http.get<Shop[]>(`${ this.config.baseUrl }/shop`, {
      ...this.config.httpOptions, 
      observe: 'response', 
      params: freeword ? { freeword: `${freeword}`, have_menu: `${have_menu}` } : {have_menu: `${have_menu}`},
      withCredentials: true
    });
  }
  
  /**
   * 店舗詳細を取得する。
   *
   * @param {string} shop_id
   * @return {*}  {Observable<HttpResponse<Shop[]>>}
   * @memberof ShoppingWebApiService
   */
  public getShopDetail(shop_id: string): Observable<HttpResponse<Shop>> {

    return this.http.get<Shop>(`${ this.config.baseUrl }/shop/${ shop_id }`, {
      ...this.config.httpOptions, 
      observe: 'response', 
      withCredentials: true
    });
  }

  /*
    買い物
  */

  /**
   * 買い物メニュー詳細を取得する。
   *
   * @param {string} shop_id
   * @param {boolean} [on_sale=true]
   * @return {*}  {Observable<HttpResponse<ShoppingMenu[]>>}
   * @memberof ShoppingWebApiService
   */
  public getShopMenu(shop_id: string, on_sale: boolean = true): Observable<HttpResponse<ShoppingMenu[]>> {

    const param = { shop_id: shop_id, on_sale: on_sale };
    return this.http.get<ShoppingMenu[]>(`${ this.config.baseUrl }/shopping/menu?${ qs.stringify(param) }`, {
      ...this.config.httpOptions, 
      observe: 'response', 
      withCredentials: true
    });
  }
  
  /**
   * 配送日時を取得し、オーダー情報を登録する。
   *
   * @param {request.Order} reqBody
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof ShoppingWebApiService
   */
  public getOrderFetch(reqBody: request.Order): Observable<HttpResponse<any>> {

    return this.http.post(`${ this.config.baseUrl }/shopping/order/fetch`, reqBody, {
      ...this.config.httpOptions, 
      observe: 'response', 
      withCredentials: true
    });
  }

  /**
   * 注文を確定する。
   *
   * @param {string} delivery_plan_id
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof ShoppingWebApiService
   */
  public createOrder(delivery_plan_id: string): Observable<HttpResponse<any>> {

    return this.http.post(`${ this.config.baseUrl }/shopping/order`, { delivery_plan_id: delivery_plan_id }, {
      ...this.config.httpOptions, 
      observe: 'response', 
      withCredentials: true
    });
  }

  /**
   * 注文情報を取得する。
   *
   * @param {parameter.Order} param
   * @return {*}  {Observable<HttpResponse<Order[]>>}
   * @memberof ShoppingWebApiService
   */
  public getOrders(param: parameter.Order): Observable<HttpResponse<Order[]>> {

    return this.http.get<Order[]>(`${ this.config.baseUrl }/shopping/order?${ qs.stringify(param) }`, {
      ...this.config.httpOptions, 
      observe: 'response', 
      withCredentials: true
    });
  }

  /**
   * order_idで指定した注文情報を取得する
   *
   * @param {string} order_id
   * @return {*}  {Observable<HttpResponse<Order>>}
   * @memberof ShoppingWebApiService
   */
  public getOrder(order_id: string): Observable<HttpResponse<Order>> {

    return this.http.get<Order>(`${ this.config.baseUrl }/shopping/order/${ order_id }`, {
      ...this.config.httpOptions, 
      observe: 'response', 
      withCredentials: true
    });
  }

  /**
   * 注文をキャンセルする。
   *
   * @param {string} order_id
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof ShoppingWebApiService
   */
  public cancelOrder(order_id: string): Observable<HttpResponse<any>> {

    const param = { as: "user" };
    return this.http.delete(`${ this.config.baseUrl }/shopping/order/${ order_id }?${ qs.stringify(param) }`, {
      ...this.config.httpOptions, 
      observe: 'response', 
      withCredentials: true
    });
  }

  /**
   * ある住所への配達が可能かどうかをチェックする。
   *    ※不可の場合はlength=0で返ってくる。
   *
   * @param {string} [address]
   * @param {common.Location} [location]
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof ShoppingWebApiService
   */
  public checkDeliverable(address?: string, location?: common.Location): Observable<HttpResponse<any>> {

    const param = {
      d_address: address, 
      d_lat: location?.lat, 
      d_lng: location?.lng
    };

    return this.http.get<any>(`${ this.config.baseUrl }/shopping/delivery-service?${ qs.stringify(param) }`, {
      ...this.config.httpOptions, 
      observe: 'response', 
      withCredentials: true
    });
  }
}
